import ArrowDown from '@assets/icons/ic-arrow-narrow-down-green.svg';
import ArrowUp from '@assets/icons/ic-arrow-narrow-up-red.svg';
import { renderCustomizedLabel } from '@components/RenderCustomizedLabel';
import { Empty } from 'antd';
import { useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { COLORS } from '../../../v3/global';
import NPSDetractorsAndPassivesModal from '../NPSDetractorsAndPassivesModal';
import './nps-problems-card.styles.scss';

type Role = 'student' | 'mentor' | 'parent';

type Props = {
  problemTitle: string;
  problemRole: string;
  statistics: any;
  problemType: string;
  date: any;
};

type LastMonthData = {
  name: string;
  value: number;
  reasonName: string;
}[];

const NPSProblemsCard = ({ statistics, problemTitle, problemRole, problemType, date }: Props) => {
  const [showMore, setShowMore] = useState(false);

  const rawData =
    problemType === 'education'
      ? // EDUCATION PROBLEMS
        [
          {
            name: 'Customer Support',
            value: statistics?.currMonthProblems?.education?.customerSupport,
            reasonName: 'CustomerSupport',
          },
          {
            name: 'Mentor Compatibility',
            value: statistics?.currMonthProblems?.education?.mentorCompatibility,
            reasonName: 'MentorCompatibility',
          },
          {
            name: 'Educational Resources Quality',
            value: statistics?.currMonthProblems?.education?.educationalResourcesQuality,
            reasonName: 'EducationalResourcesQuality',
          },
          {
            name: 'Feedback Quality',
            value: statistics?.currMonthProblems?.education?.feedbackQuality,
            reasonName: 'FeedbackQuality',
          },
          {
            name: 'Mentor Management Support',
            value: statistics?.currMonthProblems?.education?.mentorManagementSupport,
            reasonName: 'MentorManagementSupport',
          },
          {
            name: 'Number of Student Allocations',
            value: statistics?.currMonthProblems?.education?.numberOfStudentAllocations,
            reasonName: 'NumberOfStudentAllocations',
          },
          {
            name: 'Compensation Benefits',
            value: statistics?.currMonthProblems?.education?.compensationBenefits,
            reasonName: 'CompensationBenefits',
          },
          {
            name: 'Admission Results',
            value: statistics?.currMonthProblems?.education?.admissionResults,
            reasonName: 'AdmissionResults',
          },
          {
            name: 'Scheduling Flexibility',
            value: statistics?.currMonthProblems?.education?.schedulingFlexibility,
            reasonName: 'SchedulingFlexibility',
          },
          {
            name: 'Usability of Course Resources',
            value: statistics?.currMonthProblems?.education?.usabilityOfCourseResources,
            reasonName: 'UsabilityOfCourseResources',
          },
          {
            name: 'Quality of Course Resources',
            value: statistics?.currMonthProblems?.education?.qualityOfCourseResources,
            reasonName: 'QualityOfCourseResources',
          },
          {
            name: 'Training Onboarding',
            value: statistics?.currMonthProblems?.education?.trainingOnboarding,
            reasonName: 'TrainingOnboarding',
          },
          {
            name: 'Learning Progress Overview',
            value: statistics?.currMonthProblems?.education?.learningProgressOverview,
            reasonName: 'LearningProgressOverview',
          },
          {
            name: 'Quality of Students',
            value: statistics?.currMonthProblems?.education?.qualityOfStudents,
            reasonName: 'QualityOfStudents',
          },
          {
            name: 'Course Structure',
            value: statistics?.currMonthProblems?.education?.courseStructure,
            reasonName: 'CourseStructure',
          },
          {
            name: 'Other',
            value: statistics?.currMonthProblems?.education?.other,
            reasonName: 'OtherEducation',
          },
        ]
      : // PLATFORM PROBLEMS
        [
          {
            name: 'Compatibility / Accessibility',
            value: statistics?.currMonthProblems?.platform?.compatibilityAccessibility,
            reasonName: 'CompatibilityAccessibility',
          },
          {
            name: 'Customization / Personalization',
            value: statistics?.currMonthProblems?.platform?.customizationPersonalization,
            reasonName: 'CustomizationPersonalization',
          },
          {
            name: 'Documentation / Support',
            value: statistics?.currMonthProblems?.platform?.documentationSupport,
            reasonName: 'DocumentationSupport',
          },
          {
            name: 'Functionality',
            value: statistics?.currMonthProblems?.platform?.functionality,
            reasonName: 'Functionality',
          },
          {
            name: 'Performance',
            value: statistics?.currMonthProblems?.platform?.performance,
            reasonName: 'Performance',
          },
          {
            name: 'Security / Privacy',
            value: statistics?.currMonthProblems?.platform?.securityPrivacy,
            reasonName: 'SecurityPrivacy',
          },

          {
            name: 'User Interface / Experience',
            value: statistics?.currMonthProblems?.platform?.userInterfaceExperience,
            reasonName: 'UserInterfaceExperience',
          },
          {
            name: 'Other',
            value: statistics?.currMonthProblems?.platform?.other,
            reasonName: 'OtherPlatform',
          },
        ];

  const rawDataLastMonth =
    problemType === 'education'
      ? // EDUCATION PROBLEMS
        [
          {
            name: 'Customer Support',
            value: statistics?.lastMonthProblems?.education?.customerSupport,
            reasonName: 'CustomerSupport',
          },
          {
            name: 'Mentor Compatibility',
            value: statistics?.lastMonthProblems?.education?.mentorCompatibility,
            reasonName: 'MentorCompatibility',
          },
          {
            name: 'Educational Resources Quality',
            value: statistics?.lastMonthProblems?.education?.educationalResourcesQuality,
            reasonName: 'EducationalResourcesQuality',
          },
          {
            name: 'Feedback Quality',
            value: statistics?.lastMonthProblems?.education?.feedbackQuality,
            reasonName: 'FeedbackQuality',
          },
          {
            name: 'Mentor Management Support',
            value: statistics?.lastMonthProblems?.education?.mentorManagementSupport,
            reasonName: 'MentorManagementSupport',
          },
          {
            name: 'Number of Student Allocations',
            value: statistics?.lastMonthProblems?.education?.numberOfStudentAllocations,
            reasonName: 'NumberOfStudentAllocations',
          },
          {
            name: 'Compensation Benefits',
            value: statistics?.lastMonthProblems?.education?.compensationBenefits,
            reasonName: 'CompensationBenefits',
          },
          {
            name: 'Admission Results',
            value: statistics?.lastMonthProblems?.education?.admissionResults,
            reasonName: 'AdmissionResults',
          },
          {
            name: 'Scheduling Flexibility',
            value: statistics?.lastMonthProblems?.education?.schedulingFlexibility,
            reasonName: 'SchedulingFlexibility',
          },
          {
            name: 'Usability of Course Resources',
            value: statistics?.lastMonthProblems?.education?.usabilityOfCourseResources,
            reasonName: 'UsabilityOfCourseResources',
          },
          {
            name: 'Quality of Course Resources',
            value: statistics?.lastMonthProblems?.education?.qualityOfCourseResources,
            reasonName: 'QualityOfCourseResources',
          },
          {
            name: 'Training Onboarding',
            value: statistics?.lastMonthProblems?.education?.trainingOnboarding,
            reasonName: 'TrainingOnboarding',
          },
          {
            name: 'Learning Progress Overview',
            value: statistics?.lastMonthProblems?.education?.learningProgressOverview,
            reasonName: 'LearningProgressOverview',
          },
          {
            name: 'Quality of Students',
            value: statistics?.lastMonthProblems?.education?.qualityOfStudents,
            reasonName: 'QualityOfStudents',
          },
          {
            name: 'Course Structure',
            value: statistics?.lastMonthProblems?.education?.courseStructure,
            reasonName: 'CourseStructure',
          },
          {
            name: 'Other',
            value: statistics?.lastMonthProblems?.education?.other,
            reasonName: 'OtherEducation',
          },
        ]
      : // PLATFORM PROBLEMS
        [
          {
            name: 'Compatibility / Accessibility',
            value: statistics?.lastMonthProblems?.platform?.compatibilityAccessibility,
            reasonName: 'CompatibilityAccessibility',
          },
          {
            name: 'Customization / Personalization',
            value: statistics?.lastMonthProblems?.platform?.customizationPersonalization,
            reasonName: 'CustomizationPersonalization',
          },
          {
            name: 'Documentation / Support',
            value: statistics?.lastMonthProblems?.platform?.documentationSupport,
            reasonName: 'DocumentationSupport',
          },
          {
            name: 'Functionality',
            value: statistics?.lastMonthProblems?.platform?.functionality,
            reasonName: 'Functionality',
          },
          {
            name: 'Performance',
            value: statistics?.lastMonthProblems?.platform?.performance,
            reasonName: 'Performance',
          },
          {
            name: 'Security / Privacy',
            value: statistics?.lastMonthProblems?.platform?.securityPrivacy,
            reasonName: 'SecurityPrivacy',
          },

          {
            name: 'User Interface / Experience',
            value: statistics?.lastMonthProblems?.platform?.userInterfaceExperience,
            reasonName: 'UserInterfaceExperience',
          },
          {
            name: 'Other',
            value: statistics?.lastMonthProblems?.platform?.other,
            reasonName: 'OtherPlatform',
          },
        ];

  // const data = rawData.slice(0, 3);
  // const otherValue = rawData
  //   .slice(3)
  //   .reduce((acc, entry) => acc + entry.value, 0);
  // data.push({ name: 'Other', value: otherValue });
  //
  // const dataLastMonth = rawDataLastMonth.slice(0, 3);
  // const otherValueLastMonth = rawDataLastMonth
  //   .slice(3)
  //   .reduce((acc, entry) => acc + entry.value, 0);
  // dataLastMonth.push({ name: 'Other', value: otherValueLastMonth });

  // Function to calculate the percentage for an entry
  const calculatePercentage = (value: number, total: number) => {
    return total !== 0 ? Math.round((value / total) * 100) : 0;
  };

  const filteredRawData = rawData.filter((entry) => entry.value !== 0);
  const filteredRawDataLastMonth = rawDataLastMonth.filter((entry) => entry.value !== 0);

  // Sort rawData and rawDataLastMonth based on percentage values
  filteredRawData.sort((a, b) => {
    const total = filteredRawData.reduce((acc, entry) => acc + entry.value, 0);
    const percentageA = calculatePercentage(a.value, total);
    const percentageB = calculatePercentage(b.value, total);
    return percentageB - percentageA;
  });

  filteredRawDataLastMonth.sort((a, b) => {
    const total = filteredRawDataLastMonth.reduce((acc, entry) => acc + entry.value, 0);
    const percentageA = calculatePercentage(a.value, total);
    const percentageB = calculatePercentage(b.value, total);
    return percentageB - percentageA;
  });

  // Select the top 3 entries and calculate "Others" value
  const topData = filteredRawData.length === 4 ? filteredRawData : filteredRawData.slice(0, 3);
  const otherValue = filteredRawData.slice(3).reduce((acc, entry) => acc + entry.value, 0);
  if (otherValue > 0 && topData.length !== 4) {
    topData.push({
      name: 'All the rest',
      value: otherValue,
      reasonName: 'All the rest',
    });
  }

  const topDataLastMonth = filteredRawDataLastMonth.slice(0, 3);
  const otherValueLastMonth = filteredRawDataLastMonth
    .slice(3)
    .reduce((acc, entry) => acc + entry.value, 0);
  if (otherValueLastMonth > 0) {
    topDataLastMonth.push({
      name: 'All the rest',
      value: otherValueLastMonth,
      reasonName: 'All the rest',
    });
  }

  const PIE_COLORS = [
    COLORS.PRIMARY,
    COLORS.PURPLE,
    COLORS.GOLD,
    COLORS.ALERT_RED,
    '#275CA5',
    '#2828C9',
    '#7E8A2E',
    '#A83663',
    '#60AD4E',
    '#6F2F7D',
    '#DA4E18',
    '#E6CF68',
  ];

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [reason, setReason] = useState<string | undefined>();

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleOnReasonClicked = (reason: string, reasonName: string) => {
    if (reasonName === 'All the rest') {
      setShowMore(!showMore);
    } else {
      setModalTitle(problemTitle + ' ' + problemRole + ' ' + reason);
      setShowModal(true);
      setReason(reasonName);
    }
  };

  const getComparisonData = (currentValue: number, lastMonthData: LastMonthData, name: string) => {
    const lastMonthValue = lastMonthData.find((entry) => entry.name === name)?.value ?? 0;
    return { lastMonthValue, currentValue, isEqual: currentValue === lastMonthValue };
  };

  const getComparisonDisplay = (
    currentValue: number,
    lastMonthData: LastMonthData,
    name: string
  ) => {
    const { lastMonthValue, isEqual } = getComparisonData(currentValue, lastMonthData, name);
    let arrowIcon = undefined;
    let valueColor = COLORS.WHITE;

    if (!isEqual) {
      if (currentValue > lastMonthValue) {
        arrowIcon = ArrowUp;
        valueColor = COLORS.RED;
      } else {
        arrowIcon = ArrowDown;
        valueColor = COLORS.GREEN;
      }
    }

    return (
      <>
        {isEqual ? (
          <p className="mr-0.5">=</p>
        ) : (
          <img className="h-3" src={arrowIcon} alt="arrow" />
        )}
        <span style={{ color: valueColor }} className="nps-problems-details-value">
          {lastMonthValue}
        </span>
      </>
    );
  };

  return (
    <div
      style={{
        minWidth: '224px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: `${COLORS.COMPONENT_BACKGROUND} 0% 0% no-repeat padding-box`,
        boxShadow: '0px 1px 4px #15223214',
        padding: '16px 17px 4px 17px',
      }}
    >
      <NPSDetractorsAndPassivesModal
        showModal={showModal}
        handleShowModal={handleShowModal}
        title={modalTitle}
        date={date}
        role={
          (problemRole.toLowerCase() === 'student' ||
          problemRole.toLowerCase() === 'mentor' ||
          problemRole.toLowerCase() === 'parent'
            ? problemRole.toLowerCase()
            : 'student') as Role
        }
        showCategoryTabs={false}
        category={['detractors', 'passives']}
        npsType={problemTitle === 'Edu' ? 'education' : 'platform'}
        reason={reason}
      />
      <div
        style={{
          width: 'calc(100% - 10px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/*FIRST ROW*/}
        <h4
          style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
          className="nps-problems-card-title"
        >
          {problemTitle} <span style={{ color: COLORS.TEXT_PRIMARY }}>{problemRole}</span> Problems
        </h4>

        {/*GRAPH*/}
        <div style={{ marginBottom: '24px' }}>
          {topData?.length === 0 || topData.every((entry) => entry.value === 0) ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Empty description="No Data" />
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={180}>
              <PieChart>
                <Pie
                  data={showMore ? filteredRawData : topData}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="transparent"
                  labelLine={false}
                >
                  {showMore
                    ? filteredRawData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          stroke={PIE_COLORS[index % PIE_COLORS.length]}
                          strokeWidth={2}
                          fill={PIE_COLORS[index % PIE_COLORS.length]}
                        />
                      ))
                    : topData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          stroke={PIE_COLORS[index % PIE_COLORS.length]}
                          strokeWidth={2}
                          fill={PIE_COLORS[index % PIE_COLORS.length]}
                        />
                      ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>

        {/*GRAPH DETAILS*/}
        <div>
          {showMore
            ? filteredRawData.map((data, index) => {
                const colorIndex = index < PIE_COLORS.length ? index : PIE_COLORS.length - 1;

                return (
                  <div
                    onClick={() => {
                      handleOnReasonClicked(data.name, data.reasonName);
                    }}
                    key={`detail-${index}`}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: '16px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div
                        style={{
                          minWidth: '12px',
                          maxWidth: '12px',
                          minHeight: '12px',
                          maxHeight: '12px',
                          borderRadius: '2px',
                          backgroundColor: PIE_COLORS[colorIndex],
                          marginRight: '8px',
                          marginTop: '4px',
                        }}
                      ></div>
                      <div>
                        <span
                          style={{ color: COLORS.TEXT_SECONDARY }}
                          className="nps-problems-details-text"
                        >
                          {data.name}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div style={{ marginBottom: '4px' }}>
                        <span
                          style={{
                            color: COLORS.TEXT_PRIMARY,
                            whiteSpace: 'nowrap',
                          }}
                          className="nps-problems-details-value"
                        >
                          {rawData
                            ? isNaN(
                                Math.round(
                                  (data.value * 100) /
                                    rawData.reduce(
                                      (accumulator: number, currentValue: { value: number }) =>
                                        accumulator + (currentValue?.value ?? 0),
                                      0
                                    )
                                )
                              )
                              ? '-'
                              : Math.round(
                                  (data.value * 100) /
                                    rawData.reduce(
                                      (accumulator: number, currentValue: { value: number }) =>
                                        accumulator + (currentValue?.value ?? 0),
                                      0
                                    )
                                ) + '% '
                            : '-'}
                          - {data.value}
                        </span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {getComparisonDisplay(
                          data.value,
                          rawDataLastMonth,
                          filteredRawData[index].name
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            : topData.map((item, index) => {
                const colorIndex = index < PIE_COLORS.length ? index : PIE_COLORS.length - 1;

                return (
                  <div
                    onClick={() => {
                      handleOnReasonClicked(item.name, item.reasonName);
                    }}
                    key={`detail-${index}`}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      marginBottom: '16px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                      <div
                        style={{
                          minWidth: '12px',
                          maxWidth: '12px',
                          minHeight: '12px',
                          maxHeight: '12px',
                          borderRadius: '2px',
                          backgroundColor: PIE_COLORS[index],
                          marginRight: '8px',
                          marginTop: '4px',
                        }}
                      ></div>
                      <div>
                        <span
                          style={{ color: COLORS.TEXT_SECONDARY }}
                          className="nps-problems-details-text"
                        >
                          {item.name}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                      }}
                    >
                      <div style={{ marginBottom: '4px' }}>
                        <span
                          style={{
                            color: COLORS.TEXT_PRIMARY,
                            whiteSpace: 'nowrap',
                          }}
                          className="nps-problems-details-value"
                        >
                          {topData
                            ? (() => {
                                const total = topData.reduce(
                                  (accumulator: number, currentValue: { value: number }) =>
                                    accumulator + (currentValue?.value ?? 0),
                                  0
                                );

                                if (total === 0) {
                                  return '-';
                                }

                                const percentage = Math.round((item.value * 100) / total);

                                return isNaN(percentage) ? '-' : `${percentage}% `;
                              })()
                            : '-'}
                          - {item.value}
                        </span>
                      </div>
                      <div className="flex items-center">
                        {getComparisonDisplay(
                          item.value,
                          filteredRawDataLastMonth,
                          topData[index].name
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>

        {/*SEE MORE BUTTON*/}
        {filteredRawData.length <= 4 ? null : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '24px',
            }}
          >
            <span
              onClick={() => setShowMore(!showMore)}
              style={{ color: COLORS.PRIMARY, cursor: 'pointer' }}
              className="nps-problems-see-more-button"
            >
              {showMore ? 'See Less' : 'See More'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NPSProblemsCard;
