import { Participant, VideoPlayer as VideoPlayerType } from '@zoom/videosdk';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { RefObject, useContext, useEffect, useState } from 'react';
import { ZoomContext } from '../contexts/ZoomContext';

interface UsePiPProps {
  participants: Participant[];
  videoListRef: RefObject<Record<string, VideoPlayerType>>;
}

const usePiP = (props: UsePiPProps) => {
  const { participants, videoListRef } = props;
  const [participantInPiP, setParticipantInPiP] = useState<string>();

  const { zoomClient } = useContext(ZoomContext);

  const { trackEvent } = useAnalytics();

  const participantsLength = zoomClient?.getAllUser()?.length || 0;

  const getPiPParticipants = () => {
    return participants.filter(
      (participant) => participant.userId !== zoomClient?.getSessionInfo()?.userId
    );
  };

  const startPictureInPictureHandler = async () => {
    if (!videoListRef.current) return;

    const otherParticipants = getPiPParticipants();
    const participantId = otherParticipants?.[0]?.userId.toString();
    const container = videoListRef.current[participantId];
    const video = container.firstChild as HTMLVideoElement;

    setParticipantInPiP(participantId);

    if (video) {
      try {
        await video.requestPictureInPicture();
      } catch (error: any) {
        trackEvent(ANALYTICS_EVENT_NAMES.PIP_FAIL, error.message);
        console.log({ error });
      }
    }
  };

  useEffect(() => {
    if (!zoomClient) return;

    const handleVideoChange = async (payload: { state: 'Active' | 'Inactive'; userId: string }) => {
      if (document.pictureInPictureElement) {
        if (payload.state === 'Inactive') {
          document.exitPictureInPicture();
          setParticipantInPiP(undefined);
        }
      }
    };

    zoomClient.on('video-active-change', handleVideoChange);

    return () => {
      zoomClient.off('video-active-change', handleVideoChange);
    };
  }, [zoomClient, participants, participantsLength]);

  return {
    participantInPiP,
    setParticipantInPiP,
    getPiPParticipants,
    startPictureInPictureHandler,
  };
};

export default usePiP;
