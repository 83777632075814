import { renderCustomizedLabel } from '@components/RenderCustomizedLabel';
import ToggleButton from '@components/V4/ToggleButton';
import { SearchIcon } from '@heroicons/react/outline';
import { ProfileCompletionFieldsType } from '@shared/constants';
import { UserRole } from '@shared/enums';
import { Empty } from 'antd';
import { PIE_COLORS } from 'apps/agora/src/utils/constants';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { ProfilesPages } from './ProfilesModal/ProfilesModal';

type hexColor = `#${string}`;

interface ChartData {
  name: string;
  value: number;
  _id?: string;
  paramName?: string;
}

interface ProfilesBoxProps {
  isInactive?: boolean;
  title?: string;
  data: ChartData[] | [];
  type?: 'barchart' | 'piechart';
  isNormalized?: boolean;
  hasNoFilter?: boolean;
  color?: hexColor;
  shouldShowAllLink?: boolean;
  modalPage?: ProfilesPages;
  role?: UserRole;
  country?: string;
  fields?: ProfileCompletionFieldsType[];
  receivesDataAsPercentages?: boolean;
  hasActiveToggle?: boolean;
  onBarClick?: (
    modalPage?: ProfilesPages,
    param?: string,
    name?: string,
    role?: UserRole,
    fields?: ProfileCompletionFieldsType[]
  ) => void;
  onShowModalClick?: (modalPage?: ProfilesPages) => void;
  showOnlyActive?: boolean;
  onToggle?: () => void;
}

const ProfilesBox = (props: ProfilesBoxProps) => {
  const {
    isInactive = false,
    title,
    data,
    type,
    isNormalized = true,
    hasNoFilter,
    color = '#D7B037',
    shouldShowAllLink = false,
    modalPage,
    role,
    fields,
    receivesDataAsPercentages = false,
    hasActiveToggle = false,
    onBarClick,
    onShowModalClick,
    showOnlyActive = false,
    onToggle,
  } = props;

  const [filter, setFilter] = useState('');
  const [selectedName, setSelectedName] = useState<string | null>(null);

  const changeHandler = (value: string) => {
    setFilter(value);
  };

  const filteredData = useMemo(() => {
    return data
      .map((item) => {
        let newName = item.name;
        if (item.name === null) {
          newName = 'null';
        } else if (item.name.match(/^\s|\s$/)) {
          newName = `"${item.name}"`;
        }
        return {
          ...item,
          name: newName,
        };
      })
      .filter((item) => {
        const nameCondition = item.name.toLowerCase().includes(filter.toLowerCase());
        const selectedCondition = selectedName === null || item.name.includes(selectedName);
        return nameCondition && selectedCondition;
      })
      .sort((a, b) => {
        const valueDifference = b.value - a.value;
        if (valueDifference !== 0) {
          return valueDifference;
        }
        return a.name.localeCompare(b.name);
      });
  }, [data, filter, selectedName]);

  const total = filteredData.reduce((acc, current) => acc + current.value, 0);
  const maxDataValue = Math.max(...filteredData.map((item) => item.value));

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  });

  const onClickHandler = (item: ChartData) => {
    if (onBarClick) {
      onBarClick(modalPage, item._id || item.paramName, item.name, role, fields);
    }
  };

  return (
    <div
      className={mergeClassNames('bg-neutral-800 w-full p-8 flex flex-col', {
        'opacity-50': isInactive,
      })}
    >
      {title && <div className="font-bold text-base text-white mb-2 text-center">{title}</div>}

      {filteredData.length > 0 ? (
        <>
          <div className="flex justify-between gap-4 items-center">
            {!hasNoFilter && type !== 'piechart' ? (
              <div className="relative w-full flex items-center bg-surfaceObject rounded-3xl mb-2">
                <input
                  type="text"
                  className="bg-transparent text-xs border-0 w-full py-1 px-4 text-white placeholder:text-customGrey placeholder:opacity-50"
                  placeholder="Type to search"
                  value={filter}
                  onChange={(e) => changeHandler(e.target.value)}
                />
                <SearchIcon className="size-5 absolute right-6" />
              </div>
            ) : null}

            {hasActiveToggle ? (
              <label className="flex gap-2 items-center pb-2">
                <ToggleButton isChecked={showOnlyActive} onClick={onToggle} className="w-10" />
                <div className="whitespace-nowrap">Active Only</div>
              </label>
            ) : null}
          </div>

          {type === 'piechart' && (
            <div>
              {data?.length === 0 || data.every((entry) => entry.value === 0) ? (
                <div className="flex justify-center items-center w-full">
                  <Empty description="No Data" />
                </div>
              ) : (
                <>
                  <ResponsiveContainer width="100%" height={180}>
                    <PieChart>
                      <Pie
                        data={data}
                        dataKey="value"
                        cx="50%"
                        cy="50%"
                        label={renderCustomizedLabel}
                        outerRadius={80}
                        fill="transparent"
                        labelLine={false}
                      >
                        {data.map((item, index) => (
                          <Cell
                            key={`cell-${index}`}
                            stroke={PIE_COLORS[index % PIE_COLORS.length]}
                            strokeWidth={2}
                            fill={PIE_COLORS[index % PIE_COLORS.length]}
                            className={onBarClick ? 'cursor-pointer' : ''}
                            onClick={() => onClickHandler(item)}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                  <div className="w-full mt-1 relative">
                    {data.map((item, index) => (
                      <div
                        key={index}
                        className={mergeClassNames(
                          'flex items-baseline justify-between',
                          onBarClick ? 'cursor-pointer' : ''
                        )}
                        onClick={() => onClickHandler(item)}
                      >
                        <div className="flex items-center gap-2">
                          <div
                            className="size-3 min-w-3 rounded-sm"
                            style={{
                              backgroundColor: PIE_COLORS[index % PIE_COLORS.length],
                            }}
                          />
                          <div className="text-white font-bold">{item.name}</div>
                        </div>
                        <div
                          className="flex-grow bg-repeat-x h-px mx-2"
                          style={{
                            backgroundImage:
                              "url(\"data:image/svg+xml,%3Csvg width='10' height='1' viewBox='0 0 10 1' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1' cy='0.5' r='0.5' fill='white'/%3E%3C/svg%3E\")",
                          }}
                        />
                        <span className="font-semibold text-xs" style={{ color: color }}>
                          {formatter.format(item.value)}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}

          {type !== 'piechart' && (
            <div className="max-h-60 overflow-y-auto pe-4 -me-4">
              {filteredData.map((item) => (
                <FilteredDataItem
                  key={item.name}
                  item={item}
                  onClickHandler={onClickHandler}
                  hasOnClickHandler={!!onClickHandler}
                  color={color}
                  total={total}
                  maxDataValue={maxDataValue}
                  isNormalized={isNormalized}
                  receivesDataAsPercentages={receivesDataAsPercentages}
                  formatter={formatter}
                />
              ))}
            </div>
          )}

          {shouldShowAllLink ? (
            <span
              onClick={() => onShowModalClick?.(modalPage)}
              style={{ color }}
              className="nps-problems-see-all-button cursor-pointer mt-8"
            >
              See All
            </span>
          ) : null}
        </>
      ) : (
        <span>No Data available</span>
      )}
    </div>
  );
};

export default ProfilesBox;

type FilteredDataItemProps = {
  item: ChartData;
  onClickHandler: (item: ChartData) => void;
  hasOnClickHandler: boolean;
  color: string;
  total: number;
  maxDataValue: number;
  isNormalized: boolean;
  receivesDataAsPercentages: boolean;
  formatter: Intl.NumberFormat;
};

const FilteredDataItem: React.FC<FilteredDataItemProps> = ({
  item,
  onClickHandler,
  hasOnClickHandler,
  color,
  total,
  maxDataValue,
  isNormalized,
  receivesDataAsPercentages,
  formatter,
}) => {
  let displayPercentage = 0;
  let rawPercentage = 0;

  if (total > 0) {
    rawPercentage = (item.value / total) * 100;
    const normalizedPercentage = (item.value / maxDataValue) * 100;
    displayPercentage = isNormalized ? normalizedPercentage : rawPercentage;
  }

  return (
    <div
      key={item.name}
      className={mergeClassNames('mt-2', hasOnClickHandler ? 'cursor-pointer' : '')}
      onClick={() => onClickHandler(item)}
    >
      <div className="flex justify-between items-baseline">
        <div className="text-sm font-semibold text-white truncate">{item.name}</div>
        <div className="text-xs whitespace-nowrap">
          <span className="font-semibold" style={{ color }}>
            {item.value}
            {receivesDataAsPercentages ? '%' : ' '}
          </span>
          {total > 0 && !receivesDataAsPercentages ? (
            <span className="text-customGrey">({formatter.format(rawPercentage)}%)</span>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="bg-black w-full h-2 mt-1 relative">
        <div
          className="h-2"
          style={{
            width: `${receivesDataAsPercentages ? item.value * 100 : displayPercentage}%`,
            backgroundColor: color,
          }}
        />
      </div>
    </div>
  );
};
