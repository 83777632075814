export const phoneRegex = new RegExp(
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
);

export const PUBLIC_PATHS = [
  '/parent-view',
  '/auth',
  '/licenses',
  '/payment/success',
  '/referral',
  '/thank-you',
  '/login-error',
  '/terms-and-conditions',
];

// Enum for Event Names
export enum ANALYTICS_EVENT_NAMES {
  // Session Scheduling (Mentors)
  SCHEDULE_MEETING_MODAL_OPEN = 'SCHEDULE_MEETING_MODAL_OPEN',
  SCHEDULE_MEETING_SUCCESS = 'SCHEDULE_MEETING_SUCCESS',
  SCHEDULE_MEETING_FAIL = 'SCHEDULE_MEETING_FAIL',
  SCHEDULE_MEETING_TRIGGER = 'SCHEDULE_MEETING_TRIGGER',
  SCHEDULE_MEETING_MODAL_CLOSE = 'SCHEDULE_MEETING_MODAL_CLOSE',
  RESCHEDULE_MEETING_MODAL_OPEN = 'RESCHEDULE_MEETING_MODAL_OPEN',
  RESCHEDULE_MEETING_SUCCESS = 'RESCHEDULE_MEETING_SUCCESS',
  RESCHEDULE_MEETING_FAIL = 'RESCHEDULE_MEETING_FAIL',
  RESCHEDULE_MEETING_TRIGGER = 'RESCHEDULE_MEETING_TRIGGER',
  RESCHEDULE_MEETING_MODAL_CLOSE = 'RESCHEDULE_MEETING_MODAL_CLOSE',

  // Video
  MEETING_JOIN = 'MEETING_JOIN',
  MEETING_JOIN_FAIL = 'MEETING_JOIN_FAIL',
  MEETING_LEAVE = 'MEETING_LEAVE',
  MEETING_LEAVE_FAIL = 'MEETING_LEAVE_FAIL',
  SHARE_SCREEN_START = 'SHARE_SCREEN_START',
  SHARE_SCREEN_END = 'SHARE_SCREEN_END',
  SHARE_SCREEN_END_FAIL = 'SHARE_SCREEN_END_FAIL',
  SHARE_SCREEN_FAIL = 'SHARE_SCREEN_FAIL',
  SHARE_SCREEN_VIEW_FAIL = 'SHARE_SCREEN_VIEW_FAIL',
  CAMERA_START_FAIL = 'CAMERA_START_FAIL',
  MICROPHONE_START_FAIL = 'MICROPHONE_START_FAIL',
  PIP_FAIL = 'PIP_FAIL',

  // Mentor Profile
  PROFILE_CHANGE = 'PROFILE_CHANGE',
  PROFILE_PAYMENT_CHANGE = 'PROFILE_PAYMENT_CHANGE',
  PROFILE_COURSES_CAN_TEACH_CHANGE = 'PROFILE_COURSES_CAN_TEACH_CHANGE',
  PROFILE_SUBJECTS_CAN_TEACH_CHANGE = 'PROFILE_SUBJECTS_CAN_TEACH_CHANGE',
  PROFILE_TAB_CHANGE = 'PROFILE_TAB_CHANGE',
  PROFILE_CAPACITY_CHANGE = 'PROFILE_CAPACITY_CHANGE',

  // Student Profile
  STUDENT_PROFILE_CHANGE_BY_MENTOR = 'STUDENT_PROFILE_CHANGE_BY_MENTOR',

  // Profile Completion Banner
  PROFILE_BANNER_STACK_BUTTON_CLICK = 'PROFILE_BANNER_STACK_BUTTON_CLICK',

  // Banner Stack
  MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK = 'MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK',
  MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK = 'MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK',
  MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK = 'MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK',

  // Meeting
  MEETING_ADD_HOMEWORK_BUTTON_CLICK = 'MEETING_ADD_HOMEWORK_BUTTON_CLICK',
  MEETING_RESCHEDULE_BUTTON_CLICK = 'MEETING_RESCHEDULE_BUTTON_CLICK',
  MEETING_RESCHEDULE = 'MEETING_RESCHEDULE',
  MEETING_CANCEL_BUTTON_CLICK = 'MEETING_CANCEL_BUTTON_CLICK',
  MEETING_CANCEL = 'MEETING_CANCEL',
  MEETING_GIVE_FEEDBACK_BUTTON_CLICK = 'MEETING_GIVE_FEEDBACK_BUTTON_CLICK',
  MEETING_GIVE_FEEDBACK = 'MEETING_GIVE_FEEDBACK',
  CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL',
  CLOSE_FEEDBACK_MODAL_ON_MEETING_END = 'CLOSE_FEEDBACK_MODAL_ON_MEETING_END',
  FEEDBACK_MODAL_ON_END_MEETING_SHOW = 'FEEDBACK_MODAL_ON_END_MEETING_SHOW',
  FEEDBACK_ON_END_MEETING = 'FEEDBACK_ON_END_MEETING',

  // NPS Mentor
  NPS_MODAL_CLOSE = 'NPS_MODAL_CLOSE',
  NPS_MODAL_SUBMIT = 'NPS_MODAL_SUBMIT',
  GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK = 'GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK',
  GLASSDOOR_REVIEW_MODAL_CLOSE = 'GLASSDOOR_REVIEW_MODAL_CLOSE',

  // NPS Student
  GOOGLE_REVIEW_MODAL_BUTTON_CLICK = 'GOOGLE_REVIEW_MODAL_BUTTON_CLICK',
  GOOGLE_REVIEW_MODAL_CLOSE = 'GOOGLE_REVIEW_MODAL_CLOSE',

  // Session Details
  SESSION_DETAILS_GOTO_BUTTON_CLICK = 'SESSION_DETAILS_GOTO_BUTTON_CLICK',
  SESSION_NAVIGATION_ARROW_CLICK = 'SESSION_NAVIGATION_ARROW_CLICK',
  SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK = 'SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK',
  SESSION_ADD_NOTES_BUTTON_CLICK = 'SESSION_ADD_NOTES_BUTTON_CLICK',
  SESSION_ADD_NOTES = 'SESSION_ADD_NOTES',
  SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK = 'SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK',
}

// Record for Event Details
export const ANALYTICS_EVENTS: Record<
  ANALYTICS_EVENT_NAMES,
  { category: string; action: string; label?: string }
> = {
  // Session Scheduling (Mentors)
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_MODAL_OPEN]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_modal_open',
    label: 'User opened the schedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_SUCCESS]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_success',
    label: 'User successfully scheduled a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_FAIL]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_fail',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_TRIGGER]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_trigger',
    label: 'User attempted to schedule a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SCHEDULE_MEETING_MODAL_CLOSE]: {
    category: 'Session Scheduling',
    action: 'schedule_meeting_modal_close',
    label: 'User closed the schedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_OPEN]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_modal_open',
    label: 'User opened the reschedule meeting modal.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_SUCCESS]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_success',
    label: 'User successfully rescheduled a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_FAIL]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_fail',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_TRIGGER]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_trigger',
    label: 'User attempted to reschedule a meeting.',
  },
  [ANALYTICS_EVENT_NAMES.RESCHEDULE_MEETING_MODAL_CLOSE]: {
    category: 'Session Scheduling',
    action: 'reschedule_meeting_modal_close',
    label: 'User closed the reschedule meeting modal.',
  },

  // Video Events
  [ANALYTICS_EVENT_NAMES.MEETING_JOIN]: {
    category: 'Video Meeting',
    action: 'meeting_join',
    label: 'User joined the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_JOIN_FAIL]: {
    category: 'Video Meeting',
    action: 'meeting_join_fail',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_LEAVE]: {
    category: 'Video Meeting',
    action: 'meeting_leave',
    label: 'User left the meeting by pressing the leave button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_LEAVE_FAIL]: {
    category: 'Video Meeting',
    action: 'meeting_leave_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_START]: {
    category: 'Video Meeting',
    action: 'share_screen_start',
    label: 'User started sharing the screen.',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END]: {
    category: 'Video Meeting',
    action: 'share_screen_end',
    label: 'User stopped sharing the screen.',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_end_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_fail',
  },
  [ANALYTICS_EVENT_NAMES.SHARE_SCREEN_VIEW_FAIL]: {
    category: 'Video Meeting',
    action: 'share_screen_view_fail',
  },
  [ANALYTICS_EVENT_NAMES.CAMERA_START_FAIL]: {
    category: 'Video Meeting',
    action: 'camera_start_fail',
  },
  [ANALYTICS_EVENT_NAMES.MICROPHONE_START_FAIL]: {
    category: 'Video Meeting',
    action: 'microphone_start_fail',
  },
  [ANALYTICS_EVENT_NAMES.PIP_FAIL]: {
    category: 'Video Meeting',
    action: 'pip_fail',
  },

  // Mentor Profile Events
  [ANALYTICS_EVENT_NAMES.PROFILE_CHANGE]: {
    category: 'Profile',
    action: 'profile_change',
    label: 'User made changes to their profile.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_PAYMENT_CHANGE]: {
    category: 'Profile',
    action: 'profile_payment_change',
    label: 'User made changes to their payments details.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_COURSES_CAN_TEACH_CHANGE]: {
    category: 'Profile',
    action: 'profile_courses_can_teach_change',
    label: 'User made changes to their list of courses they can teach.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_SUBJECTS_CAN_TEACH_CHANGE]: {
    category: 'Profile',
    action: 'profile_subjects_can_teach_change',
    label: 'User made changes to their list of subjects they can teach.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_TAB_CHANGE]: {
    category: 'Profile',
    action: 'profile_tab_change',
    label: 'User changed the current viewing tab of their profile.',
  },
  [ANALYTICS_EVENT_NAMES.PROFILE_CAPACITY_CHANGE]: {
    category: 'Profile',
    action: 'profile_capacity_change',
    label: 'User changed their capacity.',
  },

  // Student Profile Events
  [ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR]: {
    category: 'Student Profile',
    action: 'student_profile_change_by_mentor',
    label: 'Mentor made changes to the student’s profile.',
  },

  // Profile Completion Banner Events
  [ANALYTICS_EVENT_NAMES.PROFILE_BANNER_STACK_BUTTON_CLICK]: {
    category: 'Profile Completion Banner',
    action: 'profile_banner_stack_button_click',
    label: 'User clicked on the profile completion banner button: {buttonLabel}.',
  },

  // Banner Stack Events
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_NAVIGATION_ARROW_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_navigation_arrow_click',
    label: 'Mentor clicked the navigation arrow button.',
  },
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_UPCOMING_STUDENTS_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_upcoming_students_click',
    label: 'Mentor clicked the "Upcoming Students" button.',
  },
  [ANALYTICS_EVENT_NAMES.MENTOR_BANNER_STACK_SUBMIT_FEEDBACK_CLICK]: {
    category: 'Mentor Profile Completion Banner',
    action: 'mentor_banner_stack_submit_feedback_click',
    label: 'Mentor clicked the "Submit Feedback" button.',
  },

  // Meeting Events
  [ANALYTICS_EVENT_NAMES.MEETING_ADD_HOMEWORK_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_add_homework_button_click',
    label: 'User clicked the "Add Homework" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_reschedule_button_click',
    label: 'User clicked the "Reschedule" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_RESCHEDULE]: {
    category: 'Meeting',
    action: 'meeting_reschedule',
    label: 'User rescheduled the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_CANCEL_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_cancel_button_click',
    label: 'User clicked the "Cancel" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_CANCEL]: {
    category: 'Meeting',
    action: 'meeting_cancel',
    label: 'User cancelled the meeting.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK_BUTTON_CLICK]: {
    category: 'Meeting',
    action: 'meeting_mentor_give_feedback_button_click',
    label: 'User clicked the "Give Feedback" button.',
  },
  [ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK]: {
    category: 'Meeting',
    action: 'meeting_give_feedback',
    label: 'User gave feedback.',
  },
  [ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL]: {
    category: 'Meeting',
    action: 'close_feedback_modal',
    label: 'User closed the feedback modal.',
  },
  [ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL_ON_MEETING_END]: {
    category: 'Meeting',
    action: 'close_feedback_modal_on_meeting_end',
    label: 'User closed the feedback modal after it appeared on meeting end.',
  },
  [ANALYTICS_EVENT_NAMES.FEEDBACK_MODAL_ON_END_MEETING_SHOW]: {
    category: 'Meeting',
    action: 'feedback_modal_on_end_meeting_show',
    label: 'Modal appeared after meeting ended.',
  },
  [ANALYTICS_EVENT_NAMES.FEEDBACK_ON_END_MEETING]: {
    category: 'Meeting',
    action: 'feedback_on_end_meeting',
    label: 'User gave feedback after meeting ended.',
  },

  // NPS Mentor Events
  [ANALYTICS_EVENT_NAMES.NPS_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'NPS_modal_close',
    label: 'User closed the NPS modal.',
  },
  [ANALYTICS_EVENT_NAMES.NPS_MODAL_SUBMIT]: {
    category: 'NPS',
    action: 'NPS_modal_submit',
    label: 'User submitted the NPS.',
  },
  [ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_BUTTON_CLICK]: {
    category: 'NPS',
    action: 'glassdoor_review_modal_button_click',
    label: 'User clicked the "Write us a Glassdoor Review".',
  },
  [ANALYTICS_EVENT_NAMES.GLASSDOOR_REVIEW_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'glassdoor_review_modal_close',
    label: 'User closed the Glassdoor review modal.',
  },

  // NPS Student Events
  [ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_BUTTON_CLICK]: {
    category: 'NPS',
    action: 'google_review_modal_button_click',
    label: 'User clicked the "Write us a Google Review".',
  },
  [ANALYTICS_EVENT_NAMES.GOOGLE_REVIEW_MODAL_CLOSE]: {
    category: 'NPS',
    action: 'google_review_modal_close',
    label: 'User closed the Google review modal.',
  },

  // Session Details Events
  [ANALYTICS_EVENT_NAMES.SESSION_DETAILS_GOTO_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_details_goto_button_click',
    label: 'User clicked the go to session details button of an individual meeting.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_NAVIGATION_ARROW_CLICK]: {
    category: 'Session Details',
    action: 'session_navigation_arrow_click',
    label: 'User clicked the navigation arrow button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_SUBMIT_FEEDBACK_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_submit_feedback_button_click',
    label: 'User clicked the "Submit Feedback" button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_add_notes_button_click',
    label: 'User clicked the "Add Notes" button.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_ADD_NOTES]: {
    category: 'Session Details',
    action: 'session_add_notes',
    label: 'User added notes.',
  },
  [ANALYTICS_EVENT_NAMES.SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK]: {
    category: 'Session Details',
    action: 'session_show_entire_transcript_button_click',
    label: 'User clicked the "Show Entire Transcript" button.',
  },
};

export const PIE_COLORS = ['#DA4E18', '#E6CF68', '#60AD4E', '#6F2F7D', '#275CA5'];
