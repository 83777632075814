import { PaginationProps } from '@components/V4/Pagination';
import Table from '@components/V4/Table/Table';
import { UserRole } from '@shared/enums';

interface Profile {
  _id: string;
  fullName: string;
  email: string;
  phoneNumber: {
    number: string;
  };
}

interface ProfilesModalTableProps {
  usersList: Profile[];
  areUsersLoading: boolean;
  pagination: PaginationProps;
  userModalOpenHandler: (userId: string) => void;
  role: UserRole;
}

const ProfilesModalTable = (props: ProfilesModalTableProps) => {
  const { usersList, areUsersLoading, pagination, userModalOpenHandler, role } = props;
  return (
    <Table pagination={pagination} className="w-full mb-20">
      <Table.Header>
        <Table.Row isHeader>
          <Table.HeaderCell>Full Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone Number</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {areUsersLoading && (
          <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
        )}
        {!areUsersLoading &&
          usersList &&
          usersList.length > 0 &&
          usersList.map((user: Profile) => (
            <Table.Row key={user._id}>
              <Table.Cell onClick={() => userModalOpenHandler(user._id)}>
                {user.fullName}
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.phoneNumber}</Table.Cell>
            </Table.Row>
          ))}
        {!areUsersLoading && (!usersList || usersList.length === 0) && (
          <Table.Row>
            <Table.Cell colSpan={3} className="text-center">
              No {UserRole[role]}s found!
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default ProfilesModalTable;
