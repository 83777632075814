import {
  HomeOutlined,
  BookOutlined,
  SettingOutlined,
  HighlightOutlined,
  CustomerServiceOutlined,
  CalendarOutlined,
  ReadOutlined,
  SolutionOutlined,
  UserOutlined,
  FlagOutlined,
  FileDoneOutlined,
  DollarOutlined,
  TeamOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { ReactComponent as ExamsIcon } from '@assets/icons/V4/exams-icon.svg';
import { ReactComponent as MajorsIcon } from '@assets/icons/V4/graduation-hat-icon.svg';
import { ReactComponent as SchoolIcon } from '@assets/icons/profile-icons/empty-section-icons/school-icon.svg';
import { ReactComponent as UniversityIcon } from '@assets/icons/profile-icons/university-icon.svg';
import { ACCOUNT } from '@routes';
import { Enrollment, UserRole } from '@shared/common';
import { ReactNode } from 'react';

import * as ROUTES from '../../constants/routes';
import DropdownItem from './DropdownItem';
import NavGroup from './NavGroup';
import NavItem from './NavItem';
export type Tab = {
  route: string;
  title: ReactNode;
  icon?: ReactNode;
};

export type MultiTab = {
  routes: Tab[];
  title?: ReactNode;
  icon?: ReactNode;
};

const myAccountTab = { route: ACCOUNT, title: 'My Account' };
const homeTab = (route: string) => {
  return { route, title: 'Home', icon: <HomeOutlined /> };
};

const tabsToMenus = (tabs: (Tab | MultiTab)[]) => (
  <div className="flex flex-col gap-2">
    {tabs.map((tab) =>
      'routes' in tab ? (
        <NavGroup key={tab.routes?.[0]?.route} multiTab={tab as MultiTab} />
      ) : (
        <NavItem key={tab.route} tab={tab as Tab} />
      )
    )}
  </div>
);

export const coursesTabs = (enrollments: Enrollment[]) =>
  enrollments.map(({ course }) => (
    <DropdownItem
      key={course?._id}
      route={`/course/${course?._id}`}
      title={course?.title}
      backgroundColor={course?.color}
    />
  ));

export const studentTabs = (isMobile: undefined | boolean) => (
  <>
    {isMobile ? tabsToMenus([myAccountTab]) : tabsToMenus([homeTab(ROUTES.HOME)])}
    {tabsToMenus([
      { route: ROUTES.COURSES, title: 'COURSES', icon: <BookOutlined /> },
      {
        route: ROUTES.NEW_ENROLLMENTS,
        title: 'ENROLLMENTS',
        icon: <BookOutlined />,
      },
    ])}
  </>
);

const mentorMobileTabs = [
  myAccountTab,
  {
    route: ROUTES.MENTOR_STUDENTS,
    title: 'My Students',
    icon: <HighlightOutlined />,
  },
];

const mentorDesktopTabs = [
  homeTab(ROUTES.MENTOR_HOME),
  {
    route: ROUTES.MENTOR_STUDENTS,
    title: 'My Students',
    icon: <HighlightOutlined />,
  },
  {
    route: ROUTES.MENTOR_SETTINGS,
    title: 'My Account',
    icon: <SettingOutlined />,
  },
];

export const mentorTabs = (isMobile: undefined | boolean) =>
  tabsToMenus(isMobile ? mentorMobileTabs : mentorDesktopTabs);

export const adminTabs = (roles: number[]) => {
  const tabs: (Tab | MultiTab)[] = [
    {
      route: ROUTES.MEETING_MANAGEMENT,
      title: 'All Sessions',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CalendarOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.MANAGE_COURSES,
      title: 'Courses',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ReadOutlined />
        </div>
      ),
    },

    {
      route: ROUTES.FLAGS,
      title: 'Flags',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FlagOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.PROFILES,
      title: 'Profiles',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <UserOutlined />
        </div>
      ),
    },
    {
      route: ROUTES.MANAGE_KNOWLEDGE,
      title: 'Knowledge Base',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FileTextOutlined />
        </div>
      ),
    },
  ];

  if (roles.includes(UserRole.CustomerSuccess)) {
    tabs.splice(0, 0, {
      route: ROUTES.NPS_DASHBOARD,
      title: 'NPS Dashboard',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomerServiceOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(UserRole.StudentsManager)) {
    tabs.splice(3, 0, {
      route: ROUTES.MANAGE_STUDENTS,
      title: 'Students',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <UserOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(UserRole.MentorsManager)) {
    tabs.splice(4, 0, {
      route: ROUTES.MANAGE_MENTORS_V3,
      title: 'Mentors',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SolutionOutlined />
        </div>
      ),
    });
  }

  if (roles.includes(UserRole.UsersManager)) {
    tabs.splice(4, 0, {
      route: ROUTES.MANAGE_USERS_V3,
      title: 'Users',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TeamOutlined />
        </div>
      ),
    });

    tabs.splice(6, 0, {
      routes: [
        {
          route: ROUTES.UNIVERSITIES,
          title: 'Universities',
          icon: <UniversityIcon className="h-6 w-6" />,
        },
        {
          route: ROUTES.K12,
          title: 'K12 Schools',
          icon: <SchoolIcon className="h-6 w-6" />,
        },
        {
          route: ROUTES.MAJORS,
          title: 'Majors',
          icon: <MajorsIcon className="h-6 w-6" />,
        },
        {
          route: ROUTES.EXAMS,
          title: 'Exams',
          icon: <ExamsIcon className="h-6 w-6" />,
        },
      ],
      title: 'Education',
      icon: <ExamsIcon className="h-6 w-6" />,
    });
  }

  if (roles.includes(UserRole.Financial)) {
    tabs.splice(3, 0, {
      route: ROUTES.ACTIVITY_REPORT,
      title: 'Activity Report',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FileDoneOutlined />
        </div>
      ),
    });
    tabs.splice(4, 0, {
      route: ROUTES.EXTRA_SERVICES,
      title: 'Extra Services',
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DollarOutlined />
        </div>
      ),
    });
  }

  return <>{tabsToMenus(tabs)}</>;
};
