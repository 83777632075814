import { useGetStudentsByUniversityAdmissionRate } from '@actions';
import Modal from '@components/V4/Modal/Modal';
import { PaginationProps } from '@components/V4/Pagination';
import DetailsModal, {
  DetailsModalPage,
} from '@modules/Admin/components/DetailsModal/DetailsModal';
import { UserRole } from '@shared/enums';
import { useState } from 'react';
import ProfilesModalTable from '../ProfilesModalTable';

interface StudentsByUniversityAdmissionRateProps {
  university: string;
  title: string;
}

const StudentsByUniversityAdmissionRate = (props: StudentsByUniversityAdmissionRateProps) => {
  const { university, title } = props;
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const { data: studentsByUniversityAdmissionRate, isLoading: areUsersLoading } =
    useGetStudentsByUniversityAdmissionRate({
      limit,
      page,
      sortBy,
      sortOrder,
      university,
    });

  const currentPageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeChangeHandler = (size: number) => {
    setLimit(size);
  };

  const pagination: PaginationProps = {
    pageSize: limit || 10,
    total: studentsByUniversityAdmissionRate ? studentsByUniversityAdmissionRate.total : 0,
    currentPage: page || 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const usersList = studentsByUniversityAdmissionRate?.users;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [modalPage, setModalPage] = useState<DetailsModalPage>('student');

  const userModalOpenHandler = (userId: string) => {
    setModalPage('student');
    setUserId(userId);
    setShowDetailsModal(true);
  };

  const closeSessionModalHandler = () => {
    setShowDetailsModal(false);
    setModalPage(undefined);
  };

  return (
    <>
      <Modal.Header title={`Students admitted @ ${title}:`} />
      <Modal.Body>
        <ProfilesModalTable
          usersList={usersList || []}
          areUsersLoading={areUsersLoading}
          pagination={pagination}
          userModalOpenHandler={userModalOpenHandler}
          role={UserRole.Student}
        />
      </Modal.Body>
      {showDetailsModal && (
        <DetailsModal
          page={modalPage}
          isOpen={true}
          onClose={closeSessionModalHandler}
          studentId={userId}
        />
      )}
    </>
  );
};

export default StudentsByUniversityAdmissionRate;
