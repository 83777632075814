import { createContext, useContext } from 'react';
import { AudioTransmissionQuality, VideoTransmissionQuality } from '../utils/types';
type TransmissionDirection = 'sending' | 'receiving';

type StatisticsContextType = {
  audioQuality: Record<number, Record<TransmissionDirection, AudioTransmissionQuality>>;
  videoQuality: Record<number, Record<TransmissionDirection, VideoTransmissionQuality>>;
  shareQuality: Record<number, Record<TransmissionDirection, VideoTransmissionQuality>>;
};

const StatisticsContext = createContext<StatisticsContextType | undefined>(undefined);

export const useStatistics = () => {
  const context = useContext(StatisticsContext);

  if (!context) {
    throw new Error('useStatistics must be used within a StatisticsProvider');
  }
  return context;
};

export default StatisticsContext;
