import {
  RenderRoutes,
  CourseDetailsEditorPage,
  CourseManagementPage,
  LessonEditor,
} from '@components';
import CustomerSuccessDashboard from '@components/Admin/CSDashboard';
import ActivityReport from '@modules/Admin/ActivityReport';
import ExtraServices from '@modules/Admin/ExtraServices';
import Flags from '@modules/Admin/Flags/Flags';
import KnowledgeBaseEditor from '@modules/Admin/KnowledgeBaseEditor';
import MentorsV3, { MentorPageSessionModal } from '@modules/Admin/MentorsV3';
import NPSDashboard from '@modules/Admin/NPSDashboard';
import Students from '@modules/Admin/Students';
import UsersV3 from '@modules/Admin/UsersV3';
import KnowledgeBase from '@modules/Mentor/KnowledgeBase';
import AllSessions from './pages/AllSessions/AllSessions';
import { useAuthState } from '@contexts/AuthContext';
import Majors from './pages/Majors/Majors';
import Universities from './pages/Universities/Universities';
import { UserRole } from '@shared/enums';
import Profiles from './pages/Profiles/Profiles';
import Exams from './pages/Exams/Exams';
import K12Schools from './pages/K12/K12Schools';

const Admin = () => {
  const { roles } = useAuthState();

  const routes = [
    { path: '/meeting-management', component: AllSessions },
    { path: '/manage-courses/:id', component: CourseDetailsEditorPage },
    { path: '/manage-courses', component: CourseManagementPage },
    { path: '/lesson', component: LessonEditor },
    { path: '/flags', component: Flags },
    { path: '/majors', component: Majors },
    { path: '/exams', component: Exams },
    { path: '/manage-knowledge-base', component: KnowledgeBase },
    { path: '/knowledge-base/:id', component: KnowledgeBaseEditor },
    { path: '/knowledge-base', component: KnowledgeBaseEditor },
    { path: '/profiles', component: Profiles },
  ];

  if (roles.includes(UserRole.Financial)) {
    routes.push(
      { path: '/activity-report', component: ActivityReport },
      { path: '/extra-services', component: ExtraServices }
    );
  }

  if (roles.includes(UserRole.CustomerSuccess)) {
    routes.push(
      {
        path: '/customer-success',
        component: CustomerSuccessDashboard,
      },
      {
        path: '/nps-dashboard',
        component: NPSDashboard,
      }
    );
  }

  if (roles.includes(UserRole.StudentsManager)) {
    routes.push({ path: '/students', component: Students });
  }

  if (roles.includes(UserRole.MentorsManager)) {
    routes.push({ path: '/mentors-v3', component: MentorsV3 });
  }

  if (roles.includes(UserRole.UsersManager)) {
    routes.push(
      { path: '/manage-users-v3', component: UsersV3 },
      { path: '/universities', component: Universities },
      { path: '/k12-schools', component: K12Schools }
    );
  }

  return <RenderRoutes routes={routes} redirectPath="/manage-users-v3" />;
};

export default Admin;

export { MentorPageSessionModal };
