import { AudioTransmissionQuality, VideoTransmissionQuality } from '@modules/zoom/utils/types';
import { CommandChannel, CommandChannelMsg, ConnectionState } from '@zoom/videosdk';
import { useContext, useEffect, useRef, useState } from 'react';
import { ZoomContext } from '../contexts/ZoomContext';

interface UseStatisticsListenerProps {
  connectionState: ConnectionState;
}

const useStatisticsListener = (props: UseStatisticsListenerProps) => {
  const { connectionState } = props;

  const { zoomClient, isMeetingLoading } = useContext(ZoomContext);

  const [audioQuality, setAudioQuality] = useState<
    Record<number, Record<'sending' | 'receiving', AudioTransmissionQuality>>
  >({});
  const [videoQuality, setVideoQuality] = useState<
    Record<number, Record<'sending' | 'receiving', VideoTransmissionQuality>>
  >({});
  const [shareQuality, setShareQuality] = useState<
    Record<number, Record<'sending' | 'receiving', VideoTransmissionQuality>>
  >({});

  const commandChannelRef = useRef<typeof CommandChannel>();

  useEffect(() => {
    if (isMeetingLoading || !zoomClient || connectionState !== ConnectionState.Connected) return;

    const myUserId = zoomClient?.getSessionInfo().userId;

    commandChannelRef.current = zoomClient.getCommandClient();

    const audioQualityChangeHandler = (payload: any) => {
      const data = payload.data;

      const direction = data.encoding ? 'sending' : 'receiving';

      const audioQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        direction,
        type: 'audio-quality-change',
      };

      setAudioQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: audioQuality,
        },
      }));

      const stringifiedAudioQuality = JSON.stringify(audioQuality);

      commandChannelRef.current?.send(stringifiedAudioQuality);
    };

    const videoQualityChangeHandler = (payload: any) => {
      const data = payload.data;

      const direction = data.encoding ? 'sending' : 'receiving';

      const videoQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        width: data.width,
        height: data.height,
        fps: data.fps,
        direction,
        type: 'video-quality-change',
      };

      setVideoQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: videoQuality,
        },
      }));

      const stringifiedVideoQuality = JSON.stringify(videoQuality);

      commandChannelRef.current?.send(stringifiedVideoQuality);
    };

    const shareScreenQualityChangeHandler = (payload: any) => {
      const data = payload.data;
      const direction = data.encoding ? 'sending' : 'receiving';

      const shareQuality = {
        avgLoss: data.avg_loss,
        maxLoss: data.max_loss,
        width: data.width,
        height: data.height,
        fps: data.fps,
        direction,
        type: 'share-quality-change',
      };

      setShareQuality((prev) => ({
        ...prev,
        [myUserId]: {
          ...prev[myUserId],
          [direction]: shareQuality,
        },
      }));

      const stringifiedShareQuality = JSON.stringify(shareQuality);

      commandChannelRef.current?.send(stringifiedShareQuality);
    };

    const commandChannelHandler = (payload: CommandChannelMsg) => {
      try {
        const parsedPayload = JSON.parse(payload.text);

        switch (parsedPayload.type) {
          case 'audio-quality-change':
            setAudioQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
          case 'video-quality-change':
            setVideoQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
          case 'share-quality-change':
            setShareQuality((prev) => ({
              ...prev,
              [payload.senderId]: {
                ...prev[payload.senderId],
                [parsedPayload.direction]: parsedPayload,
              },
            }));

            break;
        }
      } catch (error) {
        return;
      }
    };

    zoomClient.on(`command-channel-message`, commandChannelHandler);
    zoomClient.on('video-statistic-data-change', videoQualityChangeHandler);
    zoomClient.on('audio-statistic-data-change', audioQualityChangeHandler);
    zoomClient.on('share-statistic-data-change', shareScreenQualityChangeHandler);

    return () => {
      zoomClient.off(`command-channel-message`, commandChannelHandler);
      zoomClient.off('video-statistic-data-change', videoQualityChangeHandler);
      zoomClient.off('audio-statistic-data-change', audioQualityChangeHandler);
      zoomClient.off('share-statistic-data-change', shareScreenQualityChangeHandler);
    };
  }, [isMeetingLoading, connectionState]);

  return { audioQuality, videoQuality, shareQuality };
};

export default useStatisticsListener;
