import { useGetStudentsByEnrollments } from '@actions';
import Modal from '@components/V4/Modal/Modal';
import { PaginationProps } from '@components/V4/Pagination';
import ToggleButton from '@components/V4/ToggleButton';
import DetailsModal, {
  DetailsModalPage,
} from '@modules/Admin/components/DetailsModal/DetailsModal';
import { UserRole } from '@shared/enums';
import { useState } from 'react';
import ProfilesModalTable from '../ProfilesModalTable';

interface StudentsByEnrollmentsProps {
  courseTitle: string;
  title: string;
  showOnlyActive: boolean;
  onToggle?: () => void;
}

const StudentsByEnrollments = (props: StudentsByEnrollmentsProps) => {
  const { courseTitle, title, showOnlyActive = false, onToggle } = props;
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const { data: studentsByEnrollments, isLoading: areUsersLoading } = useGetStudentsByEnrollments({
    limit,
    page,
    sortBy,
    sortOrder,
    courseTitle,
    isActive: showOnlyActive,
  });

  const currentPageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeChangeHandler = (size: number) => {
    setLimit(size);
  };

  const pagination: PaginationProps = {
    pageSize: limit || 10,
    total: studentsByEnrollments ? studentsByEnrollments.total : 0,
    currentPage: page || 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const usersList = studentsByEnrollments?.users;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [modalPage, setModalPage] = useState<DetailsModalPage>('student');

  const userModalOpenHandler = (userId: string) => {
    setModalPage('student');
    setUserId(userId);
    setShowDetailsModal(true);
  };

  const closeSessionModalHandler = () => {
    setShowDetailsModal(false);
    setModalPage(undefined);
  };

  return (
    <>
      <Modal.Header title={`Students enrolled in ${title}:`}>
        <div className="flex justify-end">
          <label className="flex gap-2 items-center pb-2">
            <ToggleButton isChecked={showOnlyActive} onClick={onToggle} className="w-10" />
            <div className="whitespace-nowrap">Active Only</div>
          </label>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ProfilesModalTable
          usersList={usersList || []}
          areUsersLoading={areUsersLoading}
          pagination={pagination}
          userModalOpenHandler={userModalOpenHandler}
          role={UserRole.Student}
        />
      </Modal.Body>
      {showDetailsModal && (
        <DetailsModal
          page={modalPage}
          isOpen={true}
          onClose={closeSessionModalHandler}
          studentId={userId}
        />
      )}
    </>
  );
};

export default StudentsByEnrollments;
