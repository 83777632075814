import {
  useGetMentorStatisticsByCoursesCanTeach,
  useGetMentorStatisticsByMentorStatus,
  useGetMentorStatisticsByUniversity,
  useGetMentorStatisticsByUniversityRegion,
  useGetStudentStatisticsByApplicationStatus,
  useGetStudentStatisticsByEnrollments,
  useGetStudentStatisticsByUniversity,
  useGetStudentStatisticsByUniversityAdmissionRate,
  useGetStudentStatisticsByUniversityApplicationsOpened,
  useGetStudentStatisticsByUniversityApplicationsOpenedCountry,
  useGetStudentStatisticsByUniversityApplicationsOpenedRegion,
  useGetStudentStatisticsByUniversityOffersAccepted,
  useGetUserStatisticsByCity,
  useGetUserStatisticsByCountry,
  useGetUserStatisticsByProfileCompletion,
} from '@actions';
import {
  MENTOR_PROFILE_COMPLETION_FIELDS,
  ProfileCompletionFieldsType,
  STUDENT_PROFILE_ACADEMIC_COMPLETION_FIELDS,
  STUDENT_PROFILE_DEMOGRAPHIC_COMPLETION_FIELDS,
} from '@shared/constants';
import { UserFieldParam, UserRole } from '@shared/enums';
import { useState } from 'react';
import ProfilesBox from './ProfilesBox';
import ProfilesModal, { ProfilesPages } from './ProfilesModal/ProfilesModal';

const subjectData = [
  { name: 'Maths', value: 100 },
  { name: 'Law', value: 80 },
  { name: 'Chemistry', value: 60 },
  { name: 'Physics', value: 40 },
  { name: 'Languages', value: 20 },
  { name: 'Business', value: 10 },
];

interface ModalPageData {
  modalPage?: ProfilesPages;
  param?: string;
  paramName?: string;
  role?: UserRole;
  country?: string;
  fields?: ProfileCompletionFieldsType[];
}

interface ActivityState {
  name: string;
  isActive: boolean;
  isCurrent: boolean;
}

const Profiles = () => {
  const [activeStates, setActiveStates] = useState<ActivityState[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<ModalPageData>({});

  const currentActive = activeStates.find((a) => a.isCurrent);

  const getIsActive = (name: string) =>
    activeStates.find((a) => a.name === name)?.isActive ?? false;

  const { data: mentorStatisticsByMentorStatus } = useGetMentorStatisticsByMentorStatus();
  const { data: mentorStatisticsByCoursesCanTeach } = useGetMentorStatisticsByCoursesCanTeach();
  const { data: mentorStatisticsByUniversity } = useGetMentorStatisticsByUniversity();
  const { data: mentorStatisticsByUniversityRegion } = useGetMentorStatisticsByUniversityRegion();
  const { data: studentStatisticsByUniversity } = useGetStudentStatisticsByUniversity();
  const { data: studentStatisticsByUniversityOffersAccepted } =
    useGetStudentStatisticsByUniversityOffersAccepted();
  const { data: studentStatisticsByUniversityApplicationsOpened } =
    useGetStudentStatisticsByUniversityApplicationsOpened();
  const { data: studentStatisticsByUniversityApplicationsOpenedRegion } =
    useGetStudentStatisticsByUniversityApplicationsOpenedRegion();
  const { data: studentStatisticsByUniversityApplicationsOpenedCountry } =
    useGetStudentStatisticsByUniversityApplicationsOpenedCountry();
  const { data: studentStatisticsByEnrollments } = useGetStudentStatisticsByEnrollments({
    isActive: getIsActive('enrollments'),
  });
  const { data: studentStatisticsByUniversityAdmissionRate } =
    useGetStudentStatisticsByUniversityAdmissionRate();
  const { data: studentStatisticsByApplicationStatus } =
    useGetStudentStatisticsByApplicationStatus();
  const { data: mentorStatisticsByProfileCompletion } = useGetUserStatisticsByProfileCompletion({
    role: UserRole.Mentor,
    fields: MENTOR_PROFILE_COMPLETION_FIELDS,
  });
  const { data: studentDemographicStatisticsByProfileCompletion } =
    useGetUserStatisticsByProfileCompletion({
      role: UserRole.Student,
      fields: STUDENT_PROFILE_DEMOGRAPHIC_COMPLETION_FIELDS,
    });
  const { data: studentAcademicStatisticsByProfileCompletion } =
    useGetUserStatisticsByProfileCompletion({
      role: UserRole.Student,
      fields: STUDENT_PROFILE_ACADEMIC_COMPLETION_FIELDS,
    });
  const { data: mentorStatisticsByCountry } = useGetUserStatisticsByCountry({
    role: UserRole.Mentor,
    param: UserFieldParam.Country,
  });
  const { data: studentStatisticsByCountry } = useGetUserStatisticsByCountry({
    role: UserRole.Student,
    param: UserFieldParam.Country,
  });
  const { data: studentStatisticsByCity } = useGetUserStatisticsByCity({
    role: UserRole.Student,
    param: UserFieldParam.City,
  });

  const ensureActivityExists = (name: string) => {
    setActiveStates((prev) => {
      const exists = prev.some((a) => a.name === name);
      return exists ? prev : [...prev, { name, isActive: false, isCurrent: false }];
    });
  };

  const setCurrentActivity = (name: string) => {
    ensureActivityExists(name);
    setActiveStates((prev) =>
      prev.map((a) => (a.name === name ? { ...a, isCurrent: true } : { ...a, isCurrent: false }))
    );
  };

  const toggleShowOnlyActiveHandler = (name: string) => {
    setActiveStates((prev) => {
      const exists = prev.some((a) => a.name === name);
      return exists
        ? prev.map((a) =>
            a.name === name
              ? { ...a, isActive: !a.isActive, isCurrent: true }
              : { ...a, isCurrent: false }
          )
        : [
            ...prev.map((a) => ({ ...a, isCurrent: false })),
            { name, isActive: true, isCurrent: true },
          ];
    });
  };

  const toggleShowModalHandler = (
    modalPage?: ProfilesPages,
    param?: string,
    paramName?: string,
    role?: UserRole,
    fields?: ProfileCompletionFieldsType[]
  ) => {
    if (!modalPage) return;

    setModalData({ modalPage, param, paramName, role, fields });
    setShowModal((prev) => !prev);
    if (param) setCurrentActivity(param);
  };

  const onCloseModalHanlder = () => {
    setModalData({});
    setShowModal(false);
  };

  return (
    <div className="flex flex-col justify-center w-full h-full p-8">
      <ProfilesRow title="Mentors">
        <div className="w-full flex flex-col mt-8">
          <div className="text-lg font-bold text-white w-full mb-4 font-raleway">Coverage</div>
          <div className="grid gap-5 grid-cols-3 w-full">
            <ProfilesBox
              title="Mentors per Region"
              data={mentorStatisticsByUniversityRegion || []}
              type="piechart"
              modalPage={ProfilesPages.MENTORS_BY_UNIVERSITY_REGION}
              onBarClick={toggleShowModalHandler}
            />
            <ProfilesBox
              title="Mentors By Country"
              data={mentorStatisticsByCountry || []}
              modalPage={ProfilesPages.USERS_BY_COUNTRY}
              onBarClick={toggleShowModalHandler}
              role={UserRole.Mentor}
            />
            <ProfilesBox
              title="Mentors By Graduated University"
              data={mentorStatisticsByUniversity || []}
              modalPage={ProfilesPages.MENTORS_BY_UNIVERSITY}
              onBarClick={toggleShowModalHandler}
              color="#275CA5"
            />
          </div>
        </div>
        <div className="w-full flex gap-5 justify-between mt-8">
          <div className="w-full h-full">
            <div className="text-lg font-bold text-white w-full mb-4 font-raleway">&nbsp;</div>
            <div className="grid gap-5 w-full grid-cols-3">
              <ProfilesBox
                title="Courses they can teach"
                data={mentorStatisticsByCoursesCanTeach || []}
                modalPage={ProfilesPages.MENTORS_BY_COURSES_CAN_TEACH}
                onBarClick={toggleShowModalHandler}
              />
              <ProfilesBox
                title="Mentors Profile Completion Issues"
                data={mentorStatisticsByProfileCompletion || []}
                color="#D85E62"
                isNormalized={false}
                modalPage={ProfilesPages.USERS_BY_PROFILE_COMPLETION}
                onBarClick={toggleShowModalHandler}
                role={UserRole.Mentor}
                fields={MENTOR_PROFILE_COMPLETION_FIELDS}
              />
              <ProfilesBox
                title="Mentors By Status"
                data={mentorStatisticsByMentorStatus || []}
                type="piechart"
                shouldShowAllLink
                modalPage={ProfilesPages.MENTORS_BY_STATUS}
                onShowModalClick={() =>
                  toggleShowModalHandler(
                    ProfilesPages.MENTORS_BY_STATUS,
                    mentorStatisticsByMentorStatus && mentorStatisticsByMentorStatus.length > 0
                      ? mentorStatisticsByMentorStatus[0].name
                      : undefined
                  )
                }
              />
            </div>
          </div>
        </div>
      </ProfilesRow>
      <ProfilesRow title="Students">
        <div className="w-full flex flex-col mt-8">
          <div className="text-lg font-bold text-white w-full mb-4 font-raleway">
            Distribution By:
          </div>
          <div className="grid gap-5 grid-cols-3 w-full">
            <ProfilesBox
              title="Regions of Interest (Students applied in)"
              data={studentStatisticsByUniversityApplicationsOpenedRegion || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_REGION}
              onBarClick={toggleShowModalHandler}
              role={UserRole.Student}
              type="piechart"
            />
            <ProfilesBox
              title="Students By Home Country"
              data={studentStatisticsByCountry || []}
              modalPage={ProfilesPages.USERS_BY_COUNTRY}
              onBarClick={toggleShowModalHandler}
              role={UserRole.Student}
            />
            <ProfilesBox
              title="Students By Home City"
              data={studentStatisticsByCity || []}
              modalPage={ProfilesPages.USERS_BY_CITY}
              onBarClick={toggleShowModalHandler}
              role={UserRole.Student}
            />
            <ProfilesBox
              title="Countries by Number of Applications"
              data={studentStatisticsByUniversityApplicationsOpenedCountry || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_COUNTRY}
              onBarClick={toggleShowModalHandler}
              role={UserRole.Student}
            />
            <ProfilesBox
              title="Total Student Applications by University"
              data={studentStatisticsByUniversity || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY}
              onBarClick={toggleShowModalHandler}
              color="#275CA5"
            />
            <ProfilesBox
              title="Courses Enrolled In"
              data={studentStatisticsByEnrollments || []}
              modalPage={ProfilesPages.STUDENTS_BY_ENROLLMENTS}
              onBarClick={toggleShowModalHandler}
              hasActiveToggle
              onToggle={() => toggleShowOnlyActiveHandler('enrollments')}
              showOnlyActive={getIsActive('enrollments')}
            />
          </div>
        </div>
        <div className="w-full flex gap-5 justify-between mt-8">
          <div className="w-full">
            <div className="text-lg font-bold text-white w-full mb-4 font-raleway">Results</div>
            <div className="flex gap-5 w-full justify-between">
              <ProfilesBox
                title="By Application Status"
                data={studentStatisticsByApplicationStatus || []}
                modalPage={ProfilesPages.STUDENTS_BY_APPLICATION_STATUS}
                onBarClick={toggleShowModalHandler}
              />
              <ProfilesBox title="Intended Major" data={subjectData} isInactive />
            </div>
          </div>
          <div className="w-full">
            <div className="text-lg font-bold text-white w-full mb-4 font-raleway">
              Profile Completion:
            </div>
            <div className="flex gap-5 w-full justify-between">
              <ProfilesBox
                title="Demographic Student Data"
                data={studentDemographicStatisticsByProfileCompletion || []}
                color="#D85E62"
                isNormalized={false}
                modalPage={ProfilesPages.USERS_BY_PROFILE_COMPLETION}
                onBarClick={toggleShowModalHandler}
                role={UserRole.Student}
                fields={STUDENT_PROFILE_DEMOGRAPHIC_COMPLETION_FIELDS}
              />
              <ProfilesBox
                title="Results / Application Data"
                data={studentAcademicStatisticsByProfileCompletion || []}
                color="#D85E62"
                isNormalized={false}
                modalPage={ProfilesPages.USERS_BY_PROFILE_COMPLETION}
                onBarClick={toggleShowModalHandler}
                role={UserRole.Student}
                fields={STUDENT_PROFILE_ACADEMIC_COMPLETION_FIELDS}
              />
            </div>
          </div>
        </div>
      </ProfilesRow>
      <ProfilesRow title="Universities">
        <div className="w-full flex flex-col mt-8">
          <div className="grid gap-5 grid-cols-3 w-full">
            <ProfilesBox
              title="By Currently Opened / Active Applications"
              data={studentStatisticsByUniversityApplicationsOpened || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED}
              onBarClick={toggleShowModalHandler}
              color="#275CA5"
            />
            <ProfilesBox
              title="By Admission Rate"
              data={studentStatisticsByUniversityAdmissionRate || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY_ADMISSION_RATE}
              onBarClick={toggleShowModalHandler}
              color="#275CA5"
              receivesDataAsPercentages
            />
            <ProfilesBox
              title="By Offers Accepted"
              data={studentStatisticsByUniversityOffersAccepted || []}
              modalPage={ProfilesPages.STUDENTS_BY_UNIVERSITY_OFFERS_ACCEPTED}
              onBarClick={toggleShowModalHandler}
              color="#275CA5"
            />
          </div>
        </div>
      </ProfilesRow>

      <ProfilesModal
        modalPage={modalData.modalPage}
        shouldShowModal={showModal}
        onClose={onCloseModalHanlder}
        param={modalData.param}
        paramName={modalData.paramName}
        role={modalData.role}
        fields={modalData.fields}
        showOnlyActive={!!currentActive?.isActive}
        onToggle={() => currentActive && toggleShowOnlyActiveHandler(currentActive.name)}
      />
    </div>
  );
};

interface ProfilesRowProps {
  title: string;
  children: React.ReactNode;
}

const ProfilesRow = (props: ProfilesRowProps) => {
  const { title, children } = props;
  return (
    <div className="w-full flex flex-col mb-8">
      <div className="text-2xl font-bold text-white pb-2 border-b border-neutral-700 w-full font-raleway">
        {title}
      </div>
      {children}
    </div>
  );
};

export default Profiles;
