import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSaveUserProfile } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { isValidWiseField } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import SessionModalFormWrapper from '../../../DetailsModalFormWrapper';
import useServerErrors from 'apps/agora/src/hooks/useServerErrors';
import Tooltip from '@components/V4/Tooltip';
import { MentorData } from '@shared/common';
import { supportedCurrencies, countryList } from '@shared/constants';

interface PaymentsFormProps extends BasicProps {
  userId: string;
  mentorData?: MentorData;
  onCloseEditForm: () => void;
}

const checkIsFieldValid = (value: string) =>
  value !== '' && value?.length <= 32 && isValidWiseField(value);

const formDataSchema = z.object({
  payRate: z.number().min(0, { message: 'Please enter a valid number.' }),
  payRateCurrency: z.string().min(0, { message: 'Please select a valid option.' }),
  billingCurrency: z.string().refine((value) => supportedCurrencies.includes(value), {
    message: 'Please select a valid billing currency.',
  }),
  country: z.string().refine((value) => countryList.includes(value), {
    message: 'Please select a valid country.',
  }),

  vatPayer: z.number().nullable(),
  name: z
    .string()
    .min(1, { message: 'Please enter a valid name.' })
    .max(32, { message: 'Field is too long. Maximum length is 32 characters' }),
  address: z.string().min(1, { message: 'Please enter a valid address.' }),
  CUI: z.string().min(1, { message: 'Please enter a valid CUI.' }),
  IBAN: z.string().min(1, { message: 'Please enter a valid IBAN.' }).refine(checkIsFieldValid, {
    message:
      'Company IBAN must be a maximum of 32 characters and contain only valid characters: A-Z, a-z, 0-9, -./?:(),+',
  }),
});

type FormData = z.infer<typeof formDataSchema>;

const PaymentsForm = (props: PaymentsFormProps) => {
  const { userId, mentorData, onCloseEditForm } = props;

  const toast = useToast();
  const query = useQueryClient();

  const { clearServerErrors, updateServerErrors, isFieldValid, getServerErrorText } =
    useServerErrors();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    watch,
    getValues,
    reset,
    control,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      payRate: mentorData?.rate?.amount,
      payRateCurrency: mentorData?.rate?.currency,
      billingCurrency: mentorData?.rate?.billingCurrency,
      vatPayer: mentorData?.rate?.vatPayer ? 1 : 0,
      name: mentorData?.company?.name ?? '',
      address: mentorData?.company?.address ?? '',
      CUI: mentorData?.company?.CUI ?? '',
      IBAN: mentorData?.company?.IBAN ?? '',
      country: mentorData?.company?.country ?? '',
    },
  });

  const { field: payRateCurrencyField, fieldState: payRateCurrencyState } = useController({
    name: 'payRateCurrency',
    control,
  });

  const { field: vatPayerField, fieldState: vatPayerState } = useController({
    name: 'vatPayer',
    control,
  });

  const { field: countryField, fieldState: countryState } = useController({
    name: 'country',
    control,
  });

  const { field: billingCurrencyField, fieldState: billingCurrencyState } = useController({
    name: 'billingCurrency',
    control,
  });

  const editButtonHandler = () => {
    onCloseEditForm();
    reset();
  };

  const countryValue = watch('country');

  const vatPayerOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  const { mutate: saveUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');

      clearServerErrors();

      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: (error) => {
      updateServerErrors(error);

      toast.error('Profile could not be saved.');
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && mentorData) {
      saveUserProfile({
        rate: {
          ...mentorData.rate,
          amount: data.payRate,
          currency: data.payRateCurrency,
          billingCurrency: data.billingCurrency,
          vatPayer: data.vatPayer === 1 && data.country === 'Romania',
        },
        company: {
          country: data.country,
          name: data.name,
          address: data.address,
          CUI: data.CUI,
          IBAN: data.IBAN,
        },
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number | null>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (mentorData?.rate?.amount) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <SessionModalFormWrapper
      title="Edit Payment Details"
      onCancel={onCloseEditForm}
      isLoading={!!isLoading}
      submitHandler={submitHandler}
    >
      <section className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 tablet:flex-row tablet:gap-6">
          <Input
            type="number"
            id="payRate"
            label="Pay Rate"
            isTouched={touchedFields.payRate}
            isValid={!errors.payRate}
            errorText={errors.payRate?.message}
            {...register('payRate', {
              required: true,
              valueAsNumber: true,
            })}
          />
          <Select
            options={supportedCurrencies.map((currency) => ({
              label: currency,
              value: currency,
            }))}
            onSelect={payRateCurrencyField.onChange}
            onBlur={payRateCurrencyField.onBlur}
            value={payRateCurrencyField.value}
            size="large"
            id="payRateCurrency"
            label="Pay Rate Currency"
            isRequired
            allowClear={false}
            isTouched={payRateCurrencyState.isTouched}
            isValid={!payRateCurrencyState.error}
            errorText={payRateCurrencyState.error?.message}
          />
        </div>
        <div className="flex flex-col gap-2 tablet:flex-row tablet:gap-6">
          <Select
            options={supportedCurrencies.map((currency) => ({
              label: currency,
              value: currency,
            }))}
            onSelect={billingCurrencyField.onChange}
            onBlur={billingCurrencyField.onBlur}
            value={billingCurrencyField.value}
            size="large"
            id={'billingCurrency'}
            label={'Billing Currency'}
            isRequired
            allowClear={false}
            isTouched={billingCurrencyState.isTouched}
            isValid={!billingCurrencyState.error}
            errorText={billingCurrencyState.error?.message}
          />
          <Tooltip
            text={
              countryValue === 'Romania'
                ? null
                : 'VAT is not applicable for companies registered outside Romania.'
            }
            placement="top"
          >
            <Select
              options={vatPayerOptions}
              onSelect={vatPayerField.onChange}
              onBlur={vatPayerField.onBlur}
              value={countryValue !== 'Romania' ? 0 : vatPayerField.value}
              size="large"
              id={'vatPayer'}
              label={'I am a VAT Payer'}
              isDisabled={countryValue !== 'Romania'}
              isRequired
              allowClear={false}
              isTouched={countryValue !== 'Romania' ? false : vatPayerState.isTouched}
              isValid={!vatPayerState.error}
              errorText={vatPayerState.error?.message}
            />
          </Tooltip>
        </div>
        <Select
          options={countryList.map((country) => ({
            label: country,
            value: country,
          }))}
          onSelect={countryField.onChange}
          onBlur={countryField.onBlur}
          value={countryField.value}
          size="large"
          id={'country'}
          label={'Company / Country'}
          isRequired
          allowClear={false}
          isTouched={countryState.isTouched}
          isValid={!countryState.error}
          errorText={countryState.error?.message}
        />
        <Input
          type="text"
          id={'companyName'}
          label={'Company / Freelancer Name'}
          isRequired
          isTouched={touchedFields.name}
          isValid={!errors.name && isFieldValid('company.name')}
          errorText={errors.name?.message || getServerErrorText('company.name')}
          {...register('name', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'address'}
          label={'Company / Freelancer Address'}
          isRequired
          isTouched={touchedFields.address}
          isValid={!errors.address}
          errorText={errors.address?.message}
          {...register('address', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'CUI'}
          label={'Fiscal Registration Number (or equivalent)'}
          isRequired
          isTouched={touchedFields.CUI}
          isValid={!errors.CUI}
          errorText={errors.CUI?.message}
          {...register('CUI', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'IBAN'}
          label={'IBAN Number'}
          isRequired
          isTouched={touchedFields.IBAN}
          isValid={!errors.IBAN && isFieldValid('company.IBAN')}
          errorText={errors.IBAN?.message || getServerErrorText('company.IBAN')}
          {...register('IBAN', {
            required: true,
          })}
        />
      </section>
    </SessionModalFormWrapper>
  );
};

export default PaymentsForm;
