import Tabs from '@components/V4/Tabs/Tabs';
import { useAuthState } from '@contexts/AuthContext';
import { useStatistics } from '@modules/zoom/contexts/StatisticsContext';
import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import { Participant } from '@modules/zoom/utils/types';
import { CommandChannel, ConnectionState } from '@zoom/videosdk';
import { useContext, useEffect, useRef, useState } from 'react';
import AudioTab from './AudioTab';
import NetworkTab from './NetworkTab';
import ShareTab from './ShareTab';
import VideoTab from './VideoTab';

type ConnectionTab = 'network' | 'audio' | 'video' | 'share';

interface SidebarNetworkPageProps {
  networkQuality?: Record<number, { uplink: number; downlink: number }>;
  connectionState: ConnectionState;
  isScreenBeingShared: boolean;
}

const SidebarConnectionPage = (props: SidebarNetworkPageProps) => {
  const { networkQuality, isScreenBeingShared, connectionState } = props;

  const { isMentor } = useAuthState();

  const [participantsList, setParticipantsList] = useState<Participant[]>();

  const commandChannelRef = useRef<typeof CommandChannel>();

  const { zoomClient, meetingDetails, isMeetingLoading } = useContext(ZoomContext);
  const { audioQuality, videoQuality, shareQuality } = useStatistics();

  const getParticipantsList = () => {
    if (!meetingDetails) return;

    const currentZoomParticipants = zoomClient?.getAllUser();

    const participants = [
      ...meetingDetails.students.map((student) => ({
        ...student,
        role: 'Student',
      })),
    ];

    if (isMentor) {
      participants.unshift({ ...meetingDetails.mentor, role: 'Mentor' });
    }

    return participants
      .map((participant) => {
        const zoomParticipant = currentZoomParticipants?.find(
          (zp) => participant._id === zp.userIdentity
        );

        if (zoomParticipant) {
          return { ...participant, zoomUserId: zoomParticipant.userId };
        } else {
          return null;
        }
      })
      .filter((participant) => participant !== null);
  };

  useEffect(() => {
    if (isMeetingLoading || !zoomClient || connectionState !== ConnectionState.Connected) return;

    commandChannelRef.current = zoomClient.getCommandClient();

    setParticipantsList(getParticipantsList());

    const updateParticipantsHandler = () => {
      setParticipantsList(getParticipantsList());
    };

    zoomClient.on('user-added', updateParticipantsHandler);
    zoomClient.on('user-removed', updateParticipantsHandler);

    return () => {
      zoomClient?.off('user-added', updateParticipantsHandler);
      zoomClient?.off('user-removed', updateParticipantsHandler);
    };
  }, [isMeetingLoading, connectionState]);

  const participantsLength = zoomClient?.getAllUser().length || 0;

  const tabs: { label: string; value: ConnectionTab }[] = [
    { label: 'Network', value: 'network' },
    {
      label: 'Audio',
      value: 'audio',
    },
    { label: 'Video', value: 'video' },
    { label: 'Share', value: 'share' },
  ];

  const renderContent = (currentTab: ConnectionTab) => {
    switch (currentTab) {
      case 'network':
        return <NetworkTab participantsList={participantsList} networkQuality={networkQuality} />;
      case 'audio':
        return (
          <AudioTab
            hasEmptyState={participantsLength < 2}
            participantsList={participantsList}
            audioQuality={audioQuality}
          />
        );
      case 'video':
        return (
          <VideoTab
            hasEmptyState={participantsLength < 2}
            participantsList={participantsList}
            videoQuality={videoQuality}
          />
        );
      case 'share':
        return (
          <ShareTab
            hasEmptyState={!isScreenBeingShared}
            participantsList={participantsList}
            shareQuality={shareQuality}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Tabs
      className="h-[calc(100%-48px)]"
      contentWrapperClassName="overflow-auto max-h-[calc(100%-88px)] tablet:max-h-[calc(100%-48px)]"
      tabs={tabs}
      initialTab="network"
      renderContent={renderContent}
    />
  );
};

export default SidebarConnectionPage;
