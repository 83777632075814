import { ActivityType, EmploymentType, ExperienceType, GoalStatusTypes } from '../enums';

export const defaultSubjects = [
  'ACT',
  'Architecture',
  'Art & Cultural Studies',
  'Arts and Design',
  'Biology',
  'Biomedical Sciences',
  'Business & Management',
  'Chemistry',
  'Communication & Media',
  'Computer Science',
  'Criminology',
  'Earth & Environmental Sciences',
  'Economics',
  'Engineering',
  'History',
  'Law',
  'Liberal Arts & Sciences',
  'Mathematics',
  'Medicine',
  'Modern Languages; Literature & Linguistics',
  'PPE/PPLE',
  'Philosophy',
  'Physics',
  'Psychology',
  'SAT',
  'Social & Political Sciences',
  'Undecided',
];

export const subjectsOptions = [
  'Applied Arts',
  'Architecture',
  'Arts and cultural studies',
  'Biology',
  'Biomedical Sciences',
  'Business and Management',
  'Chemistry',
  'Communication & Media',
  'Computer Science',
  'Criminology',
  'Earth and Environmental Sciences',
  'Economics',
  'Engineering',
  'History',
  'Hospitality & Leisure Studies',
  'Law',
  'Liberal Arts & Sciences',
  'Mathematics',
  'Medicine',
  'Modern Languages & Linguistics',
  'Philosophy',
  'Physics',
  'PPE/PPLE',
  'Psychology',
  'Social and Political Sciences',
  'Sport Studies',
  'Undecided',
];

export const pronounsOptions = [
  { value: 'none', text: 'None selected' },
  { value: 'he/him', text: 'He/Him' },
  { value: 'she/her', text: 'She/Her' },
  { value: 'he/they', text: 'He/They' },
  { value: 'she/they', text: 'She/They' },
  { value: 'they/them', text: 'They/Them' },
];

export const interestsOptions = [
  { value: 'STEM', text: 'STEM' },
  { value: 'HUMANITIES', text: 'Humanities' },
  { value: 'SOCIAL_SCIENCES', text: 'Social Sciences' },
  { value: 'ARTS', text: 'Arts' },
  { value: 'BUSINESS', text: 'Business' },
  { value: 'MEDICINE', text: 'Medicine' },
];

export const standardisedTests = ['SAT', 'TOEFL', 'IELTS', 'CAE', 'Sprachdiplom', 'Delf', 'Other'];

export const admissionExamPrep = ['LNAT', 'IMAT', 'UCAT', 'BMAT', 'GMAT', 'Other'];

export const learningStyles = [
  'Student needs a lot of handholding.',
  'Student is an independent learner.',
  'Student enjoys being challenged.',
  'Student needs to be pushed/motivated.',
  'Student did extra-curriculars.',
  'Student didn’t do extra-curriculars.',
  'Student has won awards in their subject of interest.',
];

export const RECRUITED_FROM = [
  'LinkedIn',
  'Ads',
  'Former Student',
  'Mentor Recommendation',
  'University Post',
  'Website',
  'Other',
];

export const GOAL_STATUS_TYPES: { [key: string]: string } = {
  [GoalStatusTypes.ApplicationNotStarted]: 'Application Not Started',
  [GoalStatusTypes.ApplicationInProgress]: 'Application In Progress',
  [GoalStatusTypes.ApplicationSent]: 'Application Sent',
  [GoalStatusTypes.ApplicationRejected]: 'Application Rejected',
  [GoalStatusTypes.OfferReceived]: 'Offer Received',
  [GoalStatusTypes.OfferAccepted]: 'Offer Accepted',
  [GoalStatusTypes.OfferDeclined]: 'Offer Declined',
};

export const GOAL_STATUS_OPTIONS = Object.keys(GoalStatusTypes)
  .filter((key) => !isNaN(Number(GoalStatusTypes[key as keyof typeof GoalStatusTypes])))
  .map((key) => ({
    label: GOAL_STATUS_TYPES[GoalStatusTypes[key as keyof typeof GoalStatusTypes]],
    value: GoalStatusTypes[key as keyof typeof GoalStatusTypes],
  }));

export const EXPERIENCE_TYPE: { [key: string]: string } = {
  [ExperienceType.Work]: 'Work',
  [ExperienceType.ExtracurricularActivity]: 'Extracurricular Activity',
};

export const EMPLOYMENT_TYPE: { [key: string]: string } = {
  [EmploymentType.Fulltime]: 'Fulltime',
  [EmploymentType.PartTime]: 'Part-time',
  [EmploymentType.Internship]: 'Internship',
};

export const ACTIVITY_TYPE: { [key: string]: string } = {
  [ActivityType.AcademicAndIntellectualPursuits]: 'Academic & Intellectual Pursuits',
  [ActivityType.ArtsAndCreativeExpression]: 'Arts & Creative Expression',
  [ActivityType.AthleticsAndPhysicalActivities]: 'Athletics & Physical Activities',
  [ActivityType.CommunityServiceAndVolunteering]: 'Community Service & Volunteering',
  [ActivityType.CareerOrientedAndPracticalExperience]: 'Career-Oriented & Practical Experience',
  [ActivityType.LeadershipAndSocialImpact]: 'Leadership & Social Impact',
  [ActivityType.STEMAndTechnology]: 'STEM & Technology',
  [ActivityType.CulturalAndReligiousEngagement]: 'Cultural & Religious Engagement',
};

export const UNIVERSITY_REGION_LIST = [
  'Americas',
  'Europe',
  'Asia',
  'Oceania',
  'Africa',
  'Not Classified',
];

export const UNIVERSITY_COUNTRY_LIST = [
  'United States',
  'United Kingdom',
  'Switzerland',
  'Singapore',
  'Australia',
  'China (Mainland)',
  'Hong Kong SAR',
  'France',
  'Canada',
  'Germany',
  'South Korea',
  'Japan',
  'Netherlands',
  'Malaysia',
  'Belgium',
  'New Zealand',
  'Taiwan',
  'Argentina',
  'Sweden',
  'Ireland',
  'Brazil',
  'Chile',
  'Russia',
  'Mexico',
  'Denmark',
  'Saudi Arabia',
  'Italy',
  'Finland',
  'India',
  'Norway',
  'Qatar',
  'Austria',
  'Kazakhstan',
  'Spain',
  'South Africa',
  'Colombia',
  'United Arab Emirates',
  'Indonesia',
  'Israel',
  'Thailand',
  'Macau SAR',
  'Czech Republic',
  'Lebanon',
  'Poland',
  'Portugal',
  'Turkey',
  'Pakistan',
  'Greece',
  'Philippines',
  'Iran, Islamic Republic of',
  'Egypt',
  'Luxembourg',
  'Estonia',
  'Peru',
  'Oman',
  'Jordan',
  'Brunei',
  'Belarus',
  'Cyprus',
  'Lithuania',
  'Vietnam',
  'Costa Rica',
  'Bahrain',
  'Uzbekistan',
  'Iceland',
  'Bangladesh',
  'Hungary',
  'Slovenia',
  'Kuwait',
  'Northern Cyprus',
  'Croatia',
  'Slovakia',
  'Bulgaria',
  'Uruguay',
  'Venezuela',
  'Cuba',
  'Ukraine',
  'Latvia',
  'Serbia',
  'Malta',
  'Ethiopia',
  'Tunisia',
  'Romania',
  'Georgia',
  'Ecuador',
  'Iraq',
  'Palestinian Territory, Occupied',
  'Kyrgyzstan',
  'Ghana',
  'Uganda',
  'Kenya',
  'Armenia',
  'Azerbaijan',
  'Sri Lanka',
  'Puerto Rico',
  'Paraguay',
  'Panama',
  'Morocco',
  'Nigeria',
  'Sudan',
  'Bosnia and Herzegovina',
  'Syrian Arab Republic',
  'Dominican Republic',
  'Guatemala',
  'Bolivia',
  'Honduras',
];

export type ProfileCompletionFieldsType = {
  field: string;
  label: string;
  type: string;
};

export const MENTOR_PROFILE_COMPLETION_FIELDS: ProfileCompletionFieldsType[] = [
  { field: 'mentorData.firstName', label: 'First Name', type: 'string' },
  { field: 'mentorData.lastName', label: 'Last Name', type: 'string' },
  { field: 'mentorData.biography', label: 'Biography', type: 'string' },
  { field: 'mentorData.universities', label: 'Universities', type: 'array' },
  { field: 'educationalBackground', label: 'Graduated University', type: 'array' },
  { field: 'mentorData.majors', label: 'Majors', type: 'array' },
  { field: 'mentorData.coursesCanTeach', label: 'Courses can teach', type: 'array' },
  { field: 'mentorData.mentorCapacity.capacity', label: 'Capacity', type: 'number' },
  { field: 'mentorData.maximumActiveEnrollments', label: 'Max Active Enrollments', type: 'number' },
  { field: 'phoneNumber', label: 'Phone Number', type: 'string' },
];

export const STUDENT_PROFILE_DEMOGRAPHIC_COMPLETION_FIELDS: ProfileCompletionFieldsType[] = [
  { field: 'fullName', label: 'Full Name', type: 'string' },
  { field: 'gender', label: 'Gender', type: 'string' },
  { field: 'country', label: 'Country', type: 'string' },
  { field: 'city', label: 'City', type: 'string' },
];

export const STUDENT_PROFILE_ACADEMIC_COMPLETION_FIELDS: ProfileCompletionFieldsType[] = [
  { field: 'appliedUniversities', label: 'Applied Universities', type: 'array' },
  { field: 'educationalBackground', label: 'Educational Background', type: 'array' },
  { field: 'experiences', label: 'Experiences', type: 'array' },
];
