import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import AgoraBigSelector from '@utils/AgoraBigSelector';
import AgoraInput from '@utils/AgoraInput';
import { Checkbox } from 'antd';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import './create-new-user.styles.scss';
import { CreateMentorDetails } from '.';
import { MentorDetails } from '@shared/common';
import { countryList } from '@shared/constants';

type Props = {
  handleCurrentPage: (page: string) => void;
  mentorDetails: CreateMentorDetails;
  handleInputChangeMentor: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleArrayChangeMentor: (name: keyof CreateMentorDetails, value: string) => void;
  handleArrayRemoveMentor: (name: keyof CreateMentorDetails, index: number) => void;
  handleCountriesToStudyInSelect: (value: any) => void;
  handleGraduationYearChange: (date: Moment | null) => void;
  handleCurrencyMentor: (currency: any) => void;
  handleBillingCurrencyMentor: (currency: any) => void;
  handleVATpayer: (value: boolean) => void;
  handleCompanyCountryMentor: (value: any) => void;
};

const CreateNewUserMentorStepTwo = ({
  handleCurrentPage,
  mentorDetails,
  handleInputChangeMentor,
  handleArrayChangeMentor,
  handleArrayRemoveMentor,
  handleCountriesToStudyInSelect,
  handleGraduationYearChange,
  handleCurrencyMentor,
  handleBillingCurrencyMentor,
  handleVATpayer,
  handleCompanyCountryMentor,
}: Props) => {
  const [touched, setTouched] = useState<Record<keyof MentorDetails, boolean>>({
    fullName: false,
    phoneNumber: false,
    coursesCanTeach: false,
    mentorStatus: false,
    payRate: false,
    currency: false,
    billingCurrency: false,
    vatPayer: false,
    company: false,
    companyCountry: false,
    companyAddress: false,
    fiscalRegistrationNumber: false,
    companyIBAN: false,
  });

  const getColor = (name: keyof typeof mentorDetails) => {
    if (!touched[name] && !mentorDetails[name]) {
      return COLORS.TEXT_SECONDARY;
    } else {
      return mentorDetails[name] ? COLORS.PRIMARY : COLORS.ERROR;
    }
  };

  const areDetailsFilled = !!(
    mentorDetails.payRate &&
    mentorDetails.currency &&
    mentorDetails.billingCurrency
  );

  const currencies = ['RON', 'EUR', 'USD', 'GBP'];

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>

      <div
        style={{
          height: '60vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <h3
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginBottom: '24px',
            }}
            className="modal-page-section-title"
          >
            Billing Information
          </h3>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: 'calc(50% - 12px)' }}>
              <AgoraInput
                htmlFor="payRate"
                type="text"
                name="payRate"
                id="payRate"
                placeholder=""
                label="Pay Rate"
                color={getColor('payRate')}
                labelColor={getColor('payRate')}
                borderColor={getColor('payRate')}
                labelMargin="0 0 0 8px"
                value={mentorDetails?.payRate}
                margin="0px 0px 16px 0px"
                onChange={handleInputChangeMentor}
                isRequired
              />
            </div>

            <div
              style={{
                width: 'calc(50% - 12px)',
                marginTop: '-9px',
              }}
            >
              <AgoraBigSelector
                label="Currency"
                labelColor={getColor('currency')}
                onSelect={(value) => {
                  handleCurrencyMentor(value);
                }}
                onClear={() => {
                  handleCurrencyMentor('');
                }}
                options={currencies.map((currency) => ({
                  label: currency,
                  value: currency,
                }))}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={getColor('currency')}
                isRequired
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '8px',
            }}
          >
            <div
              style={{
                width: 'calc(50% - 12px)',
                marginTop: '-9px',
              }}
            >
              <AgoraBigSelector
                label="Billing Currency"
                labelColor={getColor('billingCurrency')}
                onSelect={(value) => {
                  handleBillingCurrencyMentor(value);
                }}
                onClear={() => {
                  handleBillingCurrencyMentor('');
                }}
                options={currencies.map((currency) => ({
                  label: currency,
                  value: currency,
                }))}
                labelKey="label"
                valueKey="value"
                margin="0 0 8px 0"
                height="38px"
                width="100%"
                borderRadius="8px"
                borderColor={getColor('billingCurrency')}
                isRequired
              />
            </div>
            {mentorDetails.companyCountry === 'Romania' && (
              <div
                style={{
                  width: 'calc(50% - 12px)',
                  marginTop: '0px',
                }}
              >
                <label
                  style={{
                    color: COLORS.TEXT_SECONDARY,
                    backgroundColor: 'transparent',
                    marginBottom: '8px',
                  }}
                  htmlFor="location"
                  className="block text-sm font-medium leading-6 text-customPrimary"
                >
                  VAT payer?
                </label>
                <Checkbox
                  onChange={(e) => {
                    handleVATpayer(e.target.checked);
                  }}
                  checked={mentorDetails.vatPayer}
                />
              </div>
            )}
          </div>

          <AgoraInput
            htmlFor="company"
            type="text"
            name="company"
            id="company"
            placeholder=""
            label="Company / Freelancer Name"
            labelColor={COLORS.TEXT_SECONDARY}
            borderColor={COLORS.TEXT_SECONDARY}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.company}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeMentor}
          />

          <AgoraBigSelector
            label="Company / Freelance Country"
            labelColor={getColor('companyCountry')}
            onSelect={(value) => {
              handleCompanyCountryMentor(value);
            }}
            onClear={() => {
              handleCompanyCountryMentor('');
            }}
            options={countryList.map((country) => ({
              label: country,
              value: country,
            }))}
            labelKey="label"
            valueKey="value"
            margin="0px 0px 16px 0px"
            height="38px"
            width="100%"
            borderRadius="8px"
            borderColor={getColor('companyCountry')}
          />

          <AgoraInput
            htmlFor="companyAddress"
            type="text"
            name="companyAddress"
            id="companyAddress"
            placeholder=""
            label="Company / Freelancer Address"
            labelColor={COLORS.TEXT_SECONDARY}
            borderColor={COLORS.TEXT_SECONDARY}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.companyAddress}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeMentor}
          />

          <AgoraInput
            htmlFor="fiscalRegistrationNumber"
            type="text"
            name="fiscalRegistrationNumber"
            id="fiscalRegistrationNumber"
            placeholder=""
            label="Fiscal registration number (or equivalent)"
            labelColor={COLORS.TEXT_SECONDARY}
            borderColor={COLORS.TEXT_SECONDARY}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.fiscalRegistrationNumber}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeMentor}
          />

          <AgoraInput
            htmlFor="companyIBAN"
            type="text"
            name="companyIBAN"
            id="companyIBAN"
            placeholder=""
            label="Company IBAN"
            color={getColor('companyIBAN')}
            labelColor={getColor('companyIBAN')}
            borderColor={getColor('companyIBAN')}
            labelMargin="0 0 0 8px"
            value={mentorDetails?.companyIBAN}
            margin="0px 0px 16px 0px"
            onChange={handleInputChangeMentor}
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <span style={{ marginRight: '16px' }} className="create-new-user-modal-steps-text">
              Step 2 of 2
            </span>
            <div
              style={{
                width: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>

              <div className="create-new-user-modal-step-container">
                <div className="create-new-user-modal-step-circle"></div>
              </div>
            </div>
          </div>
          <SecondaryButton
            onClick={() => {
              if (areDetailsFilled) {
                handleCurrentPage('create-new-user-permissions-mentor');
              }
            }}
            backgroundColor={COLORS.WHITE}
            height="40px"
            padding="12px"
            margin="0 0 0 0"
            width="100%"
            disabled={!areDetailsFilled}
          >
            <span className="create-new-user-modal-submit-button" style={{ color: COLORS.BLACK }}>
              Continue
            </span>
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default CreateNewUserMentorStepTwo;
