import { useGetUsersByProfileCompletion } from '@actions';
import Modal from '@components/V4/Modal/Modal';
import { PaginationProps } from '@components/V4/Pagination';
import DetailsModal, {
  DetailsModalPage,
} from '@modules/Admin/components/DetailsModal/DetailsModal';
import { ProfileCompletionFieldsType } from '@shared/constants';
import { UserRole } from '@shared/enums';
import { useState } from 'react';
import ProfilesModalTable from '../ProfilesModalTable';

interface UsersProfileCompletionProps {
  profileMetric: string;
  title: string;
  role: UserRole;
  fields: ProfileCompletionFieldsType[];
}

const UsersProfileCompletion = (props: UsersProfileCompletionProps) => {
  const { profileMetric, title, role, fields } = props;
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const modalPageRole = role === UserRole.Mentor ? 'mentor' : 'student';

  const { data: usersByProfileCompletion, isLoading: areUsersLoading } =
    useGetUsersByProfileCompletion({
      limit,
      page,
      sortBy,
      sortOrder,
      profileMetric,
      role,
      fields,
    });

  const currentPageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeChangeHandler = (size: number) => {
    setLimit(size);
  };

  const pagination: PaginationProps = {
    pageSize: limit || 10,
    total: usersByProfileCompletion ? usersByProfileCompletion.total : 0,
    currentPage: page || 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const usersList = usersByProfileCompletion?.users;

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [userId, setUserId] = useState<string>();
  const [modalPage, setModalPage] = useState<DetailsModalPage>(modalPageRole);

  const userModalOpenHandler = (userId: string) => {
    setModalPage(modalPageRole);
    setUserId(userId);
    setShowDetailsModal(true);
  };

  const closeSessionModalHandler = () => {
    setShowDetailsModal(false);
    setModalPage(undefined);
  };

  return (
    <>
      <Modal.Header title={`${UserRole[role]}s with incomplete ${title} data:`} />
      <Modal.Body>
        <ProfilesModalTable
          usersList={usersList || []}
          areUsersLoading={areUsersLoading}
          pagination={pagination}
          userModalOpenHandler={userModalOpenHandler}
          role={role}
        />
      </Modal.Body>
      {showDetailsModal && (
        <DetailsModal
          page={modalPage}
          isOpen={true}
          onClose={closeSessionModalHandler}
          {...(role === UserRole.Mentor ? { mentorId: userId } : { studentId: userId })}
        />
      )}
    </>
  );
};

export default UsersProfileCompletion;
