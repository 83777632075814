import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { forwardRef } from 'react';

interface VideoPlayerContainerProps extends BasicProps {
  containerClassName?: string;
}

const VideoPlayerContainer = forwardRef((props: VideoPlayerContainerProps, ref: any) => {
  const { children, className, containerClassName } = props;

  return (
    <div className={mergeClassNames('w-full flex', containerClassName)}>
      <video-player-container ref={ref}>
        <div
          className={mergeClassNames(
            'w-full grid gap-2 grid-cols-1 grid-rows-1 justify-center items-center',
            className
          )}
        >
          {children}
        </div>
      </video-player-container>
    </div>
  );
});

export default VideoPlayerContainer;
