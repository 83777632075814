import { useGetMentorsByStatus } from '@actions';
import Modal from '@components/V4/Modal/Modal';
import { PaginationProps } from '@components/V4/Pagination';
import Table from '@components/V4/Table/Table';
import Tabs from '@components/V4/Tabs/Tabs';
import { MENTOR_STATUS_V3 } from '@shared/constants';
import { MentorStatus } from '@shared/enums';
import { useState } from 'react';

interface MentorsStatusProps {
  tab?: MentorStatus;
}

const MentorsStatus = (props: MentorsStatusProps) => {
  const { tab } = props;
  const getLabel = (status: MentorStatus) => {
    switch (status) {
      case MentorStatus.InTraining:
        return 'In Training';
      case MentorStatus.Active:
        return 'Active';
      case MentorStatus.UnderObservation:
        return 'Under Observation';
      case MentorStatus.Leaving:
        return 'Leaving';
      case MentorStatus.Inactive:
        return 'Inactive';
      default:
        return 'Unknown'; // Default label or handle unexpected cases
    }
  };

  const tabs = MENTOR_STATUS_V3.map((status) => ({
    label: getLabel(status),
    value: status,
  }));

  const [currentStatus, setCurrentStatus] = useState<MentorStatus>(tab || MentorStatus.InTraining);

  return (
    <>
      <Modal.Header title="Mentors By Status" />
      <Modal.Body>
        <Tabs
          tabs={tabs}
          initialTab={tab}
          onTabChange={setCurrentStatus}
          renderContent={() => <MentorsTable mentorStatus={currentStatus} />}
        />
      </Modal.Body>
    </>
  );
};

export default MentorsStatus;

interface MentorsTableProps {
  mentorStatus: MentorStatus;
}

const MentorsTable = (props: MentorsTableProps) => {
  const { mentorStatus } = props;
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);

  const { data: mentorsByStatus, isLoading: areMentorsLoading } = useGetMentorsByStatus({
    limit,
    page,
    sortBy,
    sortOrder,
    status: mentorStatus,
  });

  const currentPageChangeHandler = (page: number) => {
    setPage(page);
  };

  const pageSizeChangeHandler = (size: number) => {
    setLimit(size);
  };

  const pagination: PaginationProps = {
    pageSize: limit || 10,
    total: mentorsByStatus ? mentorsByStatus.total : 0,
    currentPage: page || 1,
    onCurrentPageChange: currentPageChangeHandler,
    onPageSizeChange: pageSizeChangeHandler,
  };

  const mentorsList = mentorsByStatus?.users;

  return (
    <Table pagination={pagination} className="w-full mb-20">
      <Table.Header>
        <Table.Row isHeader>
          <Table.HeaderCell>Full Name</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Phone Number</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {areMentorsLoading && (
          <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
        )}
        {!areMentorsLoading &&
          mentorsList &&
          mentorsList.length > 0 &&
          mentorsList.map((mentor) => (
            <Table.Row key={mentor._id}>
              <Table.Cell>{mentor.fullName}</Table.Cell>
              <Table.Cell>{mentor.email}</Table.Cell>
              <Table.Cell>{mentor.phoneNumber.number}</Table.Cell>
            </Table.Row>
          ))}
        {!areMentorsLoading && (!mentorsList || mentorsList.length === 0) && (
          <Table.Row>
            <Table.Cell colSpan={3} className="text-center">
              No Mentors Found!
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
