import Modal from '@components/V4/Modal/Modal';
import { ProfileCompletionFieldsType } from '@shared/constants';
import { UserRole } from '@shared/enums';
import { ReactNode } from 'react';
import MentorsByCoursesCanTeach from './ProfilesModalPages/MentorsByCoursesCanTeach';
import MentorsByStatus from './ProfilesModalPages/MentorsByStatus';
import MentorsByUniversity from './ProfilesModalPages/MentorsByUniversity';
import MentorsByUniversityRegion from './ProfilesModalPages/MentorsByUniversityRegion';
import StudentsByApplicationOpenedCountry from './ProfilesModalPages/StudentsByApplicationOpenedCountry';
import StudentsByApplicationOpenedRegion from './ProfilesModalPages/StudentsByApplicationOpenedRegion';
import StudentsByApplicationStatus from './ProfilesModalPages/StudentsByApplicationStatus';
import StudentsByEnrollments from './ProfilesModalPages/StudentsByEnrollments';
import StudentsByUniversity from './ProfilesModalPages/StudentsByUniversity';
import StudentsByUniversityAdmissionRate from './ProfilesModalPages/StudentsByUniversityAdmissionRate';
import StudentsByUniversityApplicationsOpened from './ProfilesModalPages/StudentsByUniversityApplicationsOpened';
import StudentsByUniversityOffersAccepted from './ProfilesModalPages/StudentsByUniversityOffersAccepted';
import UsersByCity from './ProfilesModalPages/UsersByCity';
import UsersByCountry from './ProfilesModalPages/UsersByCountry';
import UsersByProfileCompletion from './ProfilesModalPages/UsersByProfileCompletion';

interface Props {
  modalPage?: ProfilesPages;
  shouldShowModal: boolean;
  param?: string;
  paramName?: string;
  role?: UserRole;
  country?: string;
  fields?: ProfileCompletionFieldsType[];
  showOnlyActive?: boolean;
  onToggle?: () => void;
  onClose: () => void;
}

export enum ProfilesPages {
  UNIVERSITIES,
  COUNTRIES_BY_COVERAGE,
  STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_REGION,
  MENTORS_BY_STATUS,
  STUDENTS_BY_APPLICATION_STATUS,
  MENTORS_BY_COURSES_CAN_TEACH,
  USERS_BY_PROFILE_COMPLETION,
  USERS_BY_COUNTRY,
  USERS_BY_CITY,
  MENTORS_BY_UNIVERSITY,
  MENTORS_BY_UNIVERSITY_REGION,
  STUDENTS_BY_UNIVERSITY,
  STUDENTS_BY_UNIVERSITY_OFFERS_ACCEPTED,
  STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED,
  STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_COUNTRY,
  STUDENTS_BY_UNIVERSITY_ADMISSION_RATE,
  STUDENTS_BY_ENROLLMENTS,
}

const ProfilesModal = (props: Props) => {
  const {
    modalPage,
    shouldShowModal,
    param,
    paramName,
    role,
    fields,
    showOnlyActive = false,
    onToggle,
    onClose,
  } = props;
  const getRelevantPage = () => {
    let page: ReactNode;
    switch (modalPage) {
      case ProfilesPages.MENTORS_BY_STATUS:
        page = <MentorsByStatus />;
        break;

      case ProfilesPages.STUDENTS_BY_APPLICATION_STATUS:
        page = <StudentsByApplicationStatus status={paramName || ''} title={paramName || ''} />;
        break;

      case ProfilesPages.MENTORS_BY_COURSES_CAN_TEACH:
        page = <MentorsByCoursesCanTeach courseCanTeach={param || ''} title={paramName || ''} />;
        break;

      case ProfilesPages.USERS_BY_PROFILE_COMPLETION:
        page = (
          <UsersByProfileCompletion
            profileMetric={param || ''}
            title={paramName || ''}
            role={role || 0}
            fields={fields || []}
          />
        );
        break;

      case ProfilesPages.USERS_BY_COUNTRY:
        page = (
          <UsersByCountry title={paramName || ''} role={role || 0} country={paramName || ''} />
        );
        break;

      case ProfilesPages.USERS_BY_CITY:
        page = <UsersByCity title={paramName || ''} role={role || 0} city={paramName || ''} />;
        break;

      case ProfilesPages.MENTORS_BY_UNIVERSITY:
        page = <MentorsByUniversity title={paramName || ''} university={paramName || ''} />;
        break;

      case ProfilesPages.MENTORS_BY_UNIVERSITY_REGION:
        page = <MentorsByUniversityRegion title={paramName || ''} region={paramName || ''} />;
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY:
        page = <StudentsByUniversity title={paramName || ''} university={paramName || ''} />;
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY_OFFERS_ACCEPTED:
        page = (
          <StudentsByUniversityOffersAccepted
            title={paramName || ''}
            university={paramName || ''}
          />
        );
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED:
        page = (
          <StudentsByUniversityApplicationsOpened
            title={paramName || ''}
            university={paramName || ''}
          />
        );
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY_ADMISSION_RATE:
        page = (
          <StudentsByUniversityAdmissionRate title={paramName || ''} university={paramName || ''} />
        );
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_REGION:
        page = (
          <StudentsByApplicationOpenedRegion title={paramName || ''} region={paramName || ''} />
        );
        break;

      case ProfilesPages.STUDENTS_BY_UNIVERSITY_APPLICATIONS_OPENED_COUNTRY:
        page = (
          <StudentsByApplicationOpenedCountry title={paramName || ''} country={paramName || ''} />
        );
        break;

      case ProfilesPages.STUDENTS_BY_ENROLLMENTS:
        page = (
          <StudentsByEnrollments
            title={paramName || ''}
            courseTitle={paramName || ''}
            showOnlyActive={showOnlyActive}
            {...(onToggle && { onToggle })}
          />
        );
        break;

      default:
        break;
    }

    return page;
  };
  return (
    <Modal isOpen={shouldShowModal} onClose={onClose}>
      {getRelevantPage()}
    </Modal>
  );
};

export default ProfilesModal;
