import { ReactComponent as SelectArrow } from '@assets/icons/input-arrow-expand.svg';
import { Select as SelectInner, SelectProps } from 'antd';
import { getHasValue, mergeClassNames } from 'apps/agora/src/utils/helpers';
import InputError from '../Inputs/InputError';
import InputLabel from '../Inputs/InputLabel';
import './Select.scss';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';

export type SelectValueType = string | number | null;

export interface SelectOption {
  label: string;
  value: SelectValueType;
  icon?: JSX.Element;
}

interface Props extends Omit<SelectProps, 'size'> {
  variant?: 'primary' | 'secondary' | 'ghost';
  showArrow?: boolean;
  label?: string;
  showSearch?: boolean;
  options?: SelectOption[];
  mode?: 'multiple' | 'tags';
  size?: 'default' | 'large';
  value?: any;
  isRequired?: boolean;
  isLoading?: boolean;
  className?: string;
  selectClassName?: string;
  isValid?: boolean;
  isTouched?: boolean;
  isDisabled?: boolean;
  errorText?: string;
  autoFocus?: boolean;
  allowClear?: boolean;
  allOption?: SelectOption;
  maxTagCount?: number | 'responsive';
  placeholder?: string;
  showMultiline?: boolean;
  onSelect?: (value: SelectValueType) => void;
  onChange?: (value: SelectValueType | SelectValueType[]) => void;
  onSearch?: (value: string) => void;
  onBlur?: (value: any) => void;
  onClear?: () => void;
}

const Select = (props: Props) => {
  const {
    children,
    label,
    size,
    options,
    mode = undefined,
    value,
    showArrow = true,
    isLoading = false,
    isRequired,
    isValid,
    isTouched,
    errorText,
    autoFocus,
    isDisabled = false,
    allowClear = true,
    allOption,
    maxTagCount = 'responsive',
    variant = 'primary',
    showSearch = true,
    showMultiline = false,
    placeholder = 'Search to select',
    className,
    selectClassName,
    filterOption,
    notFoundContent,
    onSelect,
    onChange,
    onDeselect,
    onSearch,
    onBlur,
    onClear,
    ...rest
  } = props;

  const clearHandler = () => {
    onSelect?.(null);
    onChange?.(null);
    onClear?.();
  };

  const selectedValues = new Set(Array.isArray(value) ? value : [value]);

  const sortedOptions = [...(options ?? [])].sort((a, b) => {
    const aSelected = selectedValues.has(a.value) ? -1 : 1;
    const bSelected = selectedValues.has(b.value) ? -1 : 1;
    return aSelected - bSelected;
  });

  const completeOptions = allOption ? [allOption, ...sortedOptions] : sortedOptions;

  return (
    <div className={mergeClassNames('custom-select', size, className)} {...rest}>
      {label && (
        <InputLabel
          label={label}
          isRequired={isRequired}
          isTouched={isTouched}
          isValid={isValid}
          className={mergeClassNames({ 'text-white': variant === 'secondary' }, 'text-xsm')}
        />
      )}

      <SelectInner
        className={mergeClassNames(
          'w-full',
          variant,
          {
            hasValue: getHasValue(value) && isTouched,
            hasError: !isValid && isTouched,
          },
          selectClassName
        )}
        value={value}
        disabled={isDisabled}
        maxTagCount={maxTagCount}
        mode={mode}
        showArrow={showArrow}
        allowClear={allowClear}
        filterOption={
          filterOption
            ? filterOption
            : (input, option) =>
                ((option?.label.props?.['data-label'] ?? '') as string)
                  ?.toLowerCase()
                  .includes(input.toLowerCase())
        }
        suffixIcon={isLoading ? <LoadingSpinner size="normal" /> : <SelectArrow />}
        autoFocus={autoFocus}
        showSearch={showSearch}
        placeholder={
          <div className="placeholder">
            <span>{placeholder}</span>
          </div>
        }
        options={completeOptions?.map((option) => ({
          value: option.value,
          label: (
            <span
              data-label={option.label}
              className={mergeClassNames(
                'custom-label flex items-center max-w-full justify-between min-w-0',
                {
                  'whitespace-normal': showMultiline,
                }
              )}
            >
              <span className={mergeClassNames({ 'w-full truncate': !showMultiline })}>
                {option.label}
              </span>
              {!!option.icon && option.icon}
            </span>
          ),
        }))}
        notFoundContent={notFoundContent}
        onSearch={onSearch}
        onChange={onChange}
        onBlur={onBlur}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onClear={clearHandler}
      >
        {children}
      </SelectInner>

      {!isValid && isTouched && errorText && <InputError errorText={errorText} />}
    </div>
  );
};

Select.Option = SelectInner.Option;

export default Select;
