import DefaultAvatarIcon from '@assets/icons/video-icons/default-avatar.svg';
import { ReactComponent as HandIcon } from '@assets/icons/video-icons/hand-icon.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as ThumbsUpIcon } from '@assets/icons/video-icons/thumbs-up-icon.svg';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { forwardRef } from 'react';

interface VideoPlayerProps extends BasicProps {
  id: string;
  size?: 'default' | 'small';
  isSpeaking?: boolean;
  hasThumbsUp?: boolean;
  showOnlyVideo?: boolean;
  hasRaisedHand?: boolean;
  isCameraActive?: boolean;
  isMicrophoneActive?: boolean;
  avatarUrl?: string;
  displayName?: string;
  containerClassName?: string;
}

const VideoPlayer = forwardRef((props: VideoPlayerProps, ref: any) => {
  const {
    id,
    size = 'default',
    isCameraActive,
    isMicrophoneActive,
    isSpeaking = false,
    hasThumbsUp = false,
    hasRaisedHand = false,
    avatarUrl,
    showOnlyVideo,
    displayName,
    className,
    containerClassName,
  } = props;

  return (
    <div className={mergeClassNames('aspect-video flex w-full', containerClassName)}>
      <div
        className={mergeClassNames(
          'custom-video-player-container aspect-video relative tablet:w-auto flex rounded-lg overflow-hidden justify-center w-auto h-full max-h-full max-w-full',
          { 'shadow-video': isSpeaking },
          className
        )}
      >
        <video-player id={id} className="custom-video-player" ref={ref}></video-player>
        {!isCameraActive && (
          <div className="absolute z-40 flex items-center justify-center bg-surfaceObject w-full h-full top-0 left-0">
            <img
              className={mergeClassNames('rounded-full w-14 h-14', {
                'laptop:w-8 laptop:h-8': size === 'small',
              })}
              src={avatarUrl || DefaultAvatarIcon}
              alt="Person"
            />
          </div>
        )}
        {!showOnlyVideo && (
          <>
            {isMicrophoneActive !== undefined && (
              <div
                className={mergeClassNames(
                  'absolute z-1000 w-6 h-6 rounded-full text-white flex items-center justify-center gap-0.5 top-2 right-4 tablet:top-4 tablet:right-6 laptop:top-8 laptop:right-12',
                  {
                    'laptop:bottom-3 laptop:right-4 laptop:top-3': size === 'small',
                    'bg-customGrey bg-opacity-50': !isMicrophoneActive,
                    'bg-customPrimary': isMicrophoneActive,
                  }
                )}
              >
                {!isMicrophoneActive ? (
                  <MicrophoneIcon className="h-3.5" />
                ) : (
                  <>
                    <div className="w-1 h-1.5 bg-white rounded-full" />
                    <div
                      className={mergeClassNames(
                        'w-1 h-1.5 transition-all ease-out duration-100 bg-white rounded-full',
                        {
                          'h-3': isSpeaking,
                        }
                      )}
                    />
                    <div className="w-1 h-1.5 bg-white rounded-full" />
                  </>
                )}
              </div>
            )}

            {!!(displayName || hasRaisedHand) && (
              <div
                className={mergeClassNames(
                  'absolute z-1000 flex rounded-full bg-customGrey bg-opacity-30 h-6 py-1 px-2 text-xs font-semibold text-black max-w-28 bottom-2 left-4 tablet:bottom-4 tablet:left-6 laptop:bottom-8 laptop:left-12',
                  {
                    'laptop:bottom-3 laptop:left-4': size === 'small',
                    'bg-customPrimary bg-opacity-100 text-white': hasRaisedHand,
                  }
                )}
              >
                {hasRaisedHand && <HandIcon className="text-white mr-2 max-h-full" />}

                <span className="truncate ">{displayName}</span>
              </div>
            )}

            {hasThumbsUp && (
              <div
                className={mergeClassNames(
                  'flex justify-center items-center w-6 h-6 rounded-full bg-customPrimary text-white absolute z-1000 top-2 left-4 tablet:top-4 tablet:left-6 laptop:top-8 laptop:left-12',
                  {
                    'laptop:top-3 laptop:left-4': size === 'small',
                  }
                )}
              >
                <ThumbsUpIcon className="w-3 h-3" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});

export default VideoPlayer;
