import RaiseHandSound from '@assets/sounds/raise-hand.wav';
import { useAuthState } from '@contexts/AuthContext';
import FeedbackModal from '@modules/Student/components/FeedbackModal/FeedbackModal';
import StatisticsContext from '@modules/zoom/contexts/StatisticsContext';
import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import usePiP from '@modules/zoom/hooks/usePiP';
import useStatisticsListener from '@modules/zoom/hooks/useStatisticsListener';
import useZoomClientEventListeners from '@modules/zoom/hooks/useZoomClientEventListeners';
import { PageNameType } from '@modules/zoom/types/PageNameType';

import ZoomVideo, {
  CommandChannel,
  LiveTranscriptionClient,
  MediaDevice,
  Participant,
  RecordingClient,
  VideoPlayer as VideoPlayerType,
  VideoQuality,
  ConnectionState,
  Stream,
} from '@zoom/videosdk';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { getTokenSilently } from 'apps/agora/src/contexts/AuthContext/auth';
import useHideHubspot from 'apps/agora/src/hooks/useHideHubspot';
import { usePrevious } from 'apps/agora/src/hooks/usePrevious';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  getStartVideoToastErrors,
  subscribeToStatisticData,
  unsubscribeToStatisticData,
} from '../../utils/helpers';
import MeetingActionButtons from './MeetingActionButtons';
import ThumbsUpLottie from './ThumbsUpLottie';
import VideoActionButtons from './VideoActionButtons';
import VideoContent from './VideoContent';
import VideoMeetingLoading from './VideoMeetingLoading';

import VideoMeetingModals, { VideoMeetingModal } from './VideoMeetingModals/VideoMeetingModals';
import VideoSidebar from './VideoSidebar/VideoSidebar';
import { findParticipantsDifference, startVideo } from './helpers';

const VideoMeeting = () => {
  const {
    videoSettings,
    isMeetingLoading,
    meetingDetails,
    zoomClient,
    activeCamera,
    activeMicrophone,
    stream,
    isCameraActive,
    isMicrophoneActive,
    webSocket,
    isSocketConnected,
    transcriptLanguage,
    setStream,
    setIsCameraActive,
    setIsMicrophoneActive,
  } = useContext(ZoomContext);

  //STATES
  const [showSidebar, setShowSidebar] = useState(false);
  const [pageName, setPageName] = useState<PageNameType | undefined>();
  const [activeModal, setActiveModal] = useState<VideoMeetingModal>();
  const [activeButton, setActiveButton] = useState<PageNameType | undefined>(undefined);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [raisedHands, setRaisedHands] = useState<Record<number, boolean>>({});
  const [thumbsUpList, setThumbsUpList] = useState<
    { senderId: number; senderName: string; timestamp: number }[]
  >([]);
  const [isViewingScreenShare, setIsViewingScreenShare] = useState(false);
  const [isScreenShareLoading, setIsScreenShareLoading] = useState(false);
  const [backgroundSuppression, setBackgroundSuppression] = useState(
    !!videoSettings.noiseSuppression
  );
  const [virtualBackground, setVirtualBackground] = useState<string | undefined>(
    videoSettings.virtualBackground
  );
  const [unreadCount, setUnreadCount] = useState(0);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [networkQuality, setNetworkQuality] = useState<
    Record<number, { uplink: number; downlink: number }>
  >({});
  const [isCameraStateSwitchLoading, setIsCameraStateSwitchLoading] = useState(false);
  const [isMicrophoneStateSwitchLoading, setIsMicrophoneStateSwitchLoading] = useState(false);
  const [isPiPActive, setIsPiPActive] = useState(false);

  //REFS
  const videoListRef = useRef<Record<string, VideoPlayerType>>({});
  const screenShareVideoContainerRef = useRef<HTMLVideoElement>(null);
  const screenShareCanvasContainerRef = useRef<HTMLCanvasElement>(null);
  const screenShareViewCanvasContainerRef = useRef<HTMLCanvasElement>(null);
  const isScreenShareLoadingRef = useRef(false);
  const shouldMuteShareAudioRef = useRef(false);

  const commandChannelRef = useRef<typeof CommandChannel>();
  const timeoutRefs = useRef<Record<string, NodeJS.Timeout | undefined>>({});

  useHideHubspot();

  const location = useLocation();

  const toast = useToast();
  const { meetingId } = useParams<{ meetingId: string }>();
  const { isMentor, userId } = useAuthState();
  const { trackEvent } = useAnalytics();

  const params = new URLSearchParams(location.search);
  const isSharing = stream?.getShareStatus() === 'sharing';
  const participantsLength = zoomClient?.getAllUser()?.length || 0;

  useEffect(() => {
    const joinMeeting = async () => {
      if (!webSocket || !isSocketConnected || !userId || isMeetingLoading) return;

      try {
        const token: string = await getTokenSilently();

        webSocket.send(
          JSON.stringify({
            type: 'join-meeting',
            meetingId,
            token: `Bearer ${token}`,
          })
        );
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    joinMeeting();
  }, [isSocketConnected, userId, isMeetingLoading]);

  const startVideoFailureHandler = (error: any) => {
    const toastMessage = getStartVideoToastErrors(error);

    trackEvent(ANALYTICS_EVENT_NAMES.CAMERA_START_FAIL, toastMessage);

    toast.error(toastMessage);
    setIsCameraActive(false);
  };

  const reattachVideos = async () => {
    const allParticipants = zoomClient?.getAllUser();

    if (!allParticipants) return;

    for (const participant of allParticipants) {
      try {
        await stream?.detachVideo(participant.userId);
      } catch (error) {
        console.log(error);
      }
    }

    for (const participant of allParticipants) {
      try {
        await stream?.attachVideo(
          participant.userId,
          VideoQuality.Video_720P,
          videoListRef.current[`${participant.userId}`]
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const renderShareScreen = async (stream: typeof Stream) => {
    if (!zoomClient || !stream) {
      return;
    }

    if (isScreenShareLoadingRef.current) return;

    const allUsers = zoomClient.getAllUser();
    const myUserId = zoomClient.getSessionInfo().userId;

    for (const user of allUsers) {
      if (
        user.sharerOn &&
        !!screenShareViewCanvasContainerRef.current &&
        user.userId !== myUserId
      ) {
        setIsScreenShareLoading(true);
        isScreenShareLoadingRef.current = true;

        try {
          await stream.stopShareView();
        } catch (error) {
          console.log(error);
        }

        try {
          await stream.startShareView(screenShareViewCanvasContainerRef.current, user.userId);
          setIsViewingScreenShare(true);
        } catch (error) {
          console.log(error);

          toast.error('Could not start viewing the screen share.');
        }

        setIsScreenShareLoading(false);
        isScreenShareLoadingRef.current = false;
      }
    }
  };

  useEffect(() => {
    if (isMeetingLoading || !zoomClient) {
      return;
    }

    setIsCameraActive(false);
    setIsMicrophoneActive(false);

    setIsCameraStateSwitchLoading(true);
    setIsMicrophoneStateSwitchLoading(true);

    let cloudRecording: typeof RecordingClient;
    let transcriptionClient: typeof LiveTranscriptionClient;

    commandChannelRef.current = zoomClient.getCommandClient();
    const stream = zoomClient.getMediaStream();

    setStream(stream);

    const visibilityChangeHandler = () => {
      if (!document.hidden) {
        renderShareScreen(stream);
        reattachVideos();
      }
    };

    (async () => {
      if (isCameraActive) {
        try {
          await startVideo({
            stream,
            cameraId: activeCamera,
            virtualBackground,
            onSuccess: () => setIsCameraActive(true),
            onFailure: startVideoFailureHandler,
          });
        } catch (error) {
          console.log(error);
        }
      }
      setIsCameraStateSwitchLoading(false);

      try {
        await stream.startAudio({
          microphoneId: activeMicrophone,
          backgroundNoiseSuppression: backgroundSuppression,
        });

        if (!isMicrophoneActive) {
          await stream.muteAudio();
          setIsMicrophoneActive(false);
        } else {
          setIsMicrophoneActive(true);
        }
      } catch (error: any) {
        trackEvent(ANALYTICS_EVENT_NAMES.MICROPHONE_START_FAIL, error.reason);

        setIsMicrophoneActive(false);
        toast.error(error.reason);
      }
      setIsMicrophoneStateSwitchLoading(false);

      renderShareScreen(stream);

      if (meetingDetails?.accessTokenData.type === 1) {
        cloudRecording = zoomClient.getRecordingClient();

        try {
          await cloudRecording.startCloudRecording();
        } catch (error) {
          console.log(error);
        }
      }
      transcriptionClient = zoomClient.getLiveTranscriptionClient();

      try {
        await transcriptionClient?.startLiveTranscription();
        if (transcriptLanguage) {
          await transcriptionClient.setSpeakingLanguage(transcriptLanguage);
        }
      } catch (error) {
        toast.error('Could not start live transcription.');
      }

      document.addEventListener('visibilitychange', visibilityChangeHandler);

      const participants = zoomClient.getAllUser();

      setParticipants(participants);

      for (const participant of participants) {
        const networkQualityInfo = stream.getNetworkQuality(participant.userId);

        if (!networkQualityInfo) continue;

        Object.keys(networkQualityInfo).forEach((key: unknown) => {
          setNetworkQuality((prev) => ({
            ...prev,
            [participant.userId]: {
              ...prev[participant.userId],
              [key as string]: networkQualityInfo[key as 'uplink' | 'downlink'],
            },
          }));
        });
      }

      subscribeToStatisticData(stream);
    })();

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);

      (async () => {
        Object.values(timeoutRefs.current).forEach((timeoutId) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        });

        unsubscribeToStatisticData(stream);
      })();
    };
  }, [isMeetingLoading]);

  useEffect(() => {
    if (!stream || isMeetingLoading || !zoomClient) {
      return;
    }

    return () => {
      (async () => {
        const cleanupTasks = [
          stream.stopAudio(),
          stream.stopVideo(),
          stream.detachVideo(zoomClient.getSessionInfo().userId),
          stream.stopShareView(),
          stream.stopShareScreen(),
          zoomClient.leave(!!isMentor),
        ];

        for (const task of cleanupTasks) {
          try {
            await task;
          } catch (error) {
            console.log(error);
          }
        }

        ZoomVideo.destroyClient();
      })();
    };
  }, [stream, isMeetingLoading, zoomClient]);

  const showSidebarHandler = (pageName: PageNameType) => {
    setShowSidebar(true);
    setPageName(pageName);
  };

  const closeSidebarHandler = () => {
    setShowSidebar(false);
    setPageName(undefined);
    setActiveButton(undefined);
  };

  const closeModalHandler = () => {
    setActiveModal(undefined);
  };

  const handleCameraButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isCameraStateSwitchLoading) {
      return;
    }

    setIsCameraStateSwitchLoading(true);

    if (stream.isCapturingVideo()) {
      try {
        await stream.stopVideo();

        setIsCameraActive(false);
      } catch (error: any) {
        toast.error(error.message);
      }
      setIsCameraStateSwitchLoading(false);

      return;
    }

    try {
      await startVideo({
        stream,
        cameraId: activeCamera,
        virtualBackground,
        onSuccess: () => setIsCameraActive(true),
        onFailure: startVideoFailureHandler,
      });
    } catch (error) {
      console.log(error);
    }

    setIsCameraStateSwitchLoading(false);
  };

  const raiseHandClickHandler = (senderId: number, isRaised: boolean, playSound = false) => {
    if (!raisedHands[senderId] && playSound) {
      const audio = new Audio(RaiseHandSound);
      audio.play();
    }
    setRaisedHands((state) => ({ ...state, [senderId]: isRaised }));
  };

  const thumbsUpClickHandler = (senderId: number, senderName: string, timestamp: number) => {
    setThumbsUpList((state) => [...state, { senderId, senderName, timestamp }]);

    timeoutRefs.current[timestamp] = setTimeout(() => {
      setThumbsUpList((state) => state.filter((thumbsUp) => thumbsUp.timestamp !== timestamp));
    }, 5000);
  };

  const isHandRaised = raisedHands[zoomClient?.getSessionInfo().userId ?? 0];

  const { connectionState, speakingParticipants, chatMessages } = useZoomClientEventListeners({
    isHandRaised,
    micList,
    cameraList,
    pageName,
    timeoutRefs,
    isCameraStateSwitchLoading,
    backgroundSuppression,
    commandChannelRef,
    screenShareViewCanvasContainerRef,
    shouldMuteShareAudioRef,
    onThumbsUpClick: thumbsUpClickHandler,
    onRaiseHandClick: raiseHandClickHandler,
    onCloseSidebar: closeSidebarHandler,
    onShowFeedbackModal: () => setShowFeedbackModal(true),
    onCloseModal: closeModalHandler,
    reattachVideos,
    renderShareScreen,
    setUnreadCount,
    setParticipants,
    setNetworkQuality,
    setIsScreenShareLoading,
    setIsViewingScreenShare,
    setCameraList,
    setMicList,
  });

  const shareScreenClickHandler = async () => {
    if (!stream || !screenShareCanvasContainerRef.current || !screenShareVideoContainerRef.current)
      return;

    if (isSharing) {
      try {
        await stream.stopShareScreen();
        trackEvent(ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END);
      } catch (error: any) {
        trackEvent(ANALYTICS_EVENT_NAMES.SHARE_SCREEN_END_FAIL, error.reason);

        toast.error(error.reason);
        throw new Error(error.reason);
      }
      return;
    }

    const screenShareContainer = stream?.isStartShareScreenWithVideoElement()
      ? screenShareVideoContainerRef.current
      : screenShareCanvasContainerRef.current;

    try {
      await stream.startShareScreen(screenShareContainer);

      trackEvent(ANALYTICS_EVENT_NAMES.SHARE_SCREEN_START);

      shouldMuteShareAudioRef.current = true;
    } catch (error: any) {
      if (error.reason !== 'user deny screen share') {
        trackEvent(ANALYTICS_EVENT_NAMES.SHARE_SCREEN_FAIL, error.reason);

        toast.error(error.reason);
      }
      throw new Error(error.reason);
    }
  };

  const handleMicrophoneButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isMicrophoneStateSwitchLoading) {
      return;
    }

    setIsMicrophoneStateSwitchLoading(true);

    if (stream.isAudioMuted()) {
      try {
        await stream.unmuteAudio();

        setIsMicrophoneActive(true);
      } catch (error: any) {
        toast.error(error.message);
      }
      setIsMicrophoneStateSwitchLoading(false);

      return;
    }

    try {
      await stream.muteAudio();
      setIsMicrophoneActive(false);
    } catch (error: any) {
      toast.error(error.message);
    }

    setIsMicrophoneStateSwitchLoading(false);
  };

  const setVideoPlayerRef = (userId: number, element: VideoPlayerType | null) => {
    if (element) {
      videoListRef.current[`${userId}`] = element;
    }
  };

  const getIsNetworkWarning = () => {
    if (!networkQuality) {
      return false;
    }

    if (isMentor) {
      return Object.values(networkQuality).some((quality) => quality?.uplink < 2);
    }

    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!myUserId) return false;

    const myNetworkQuality = networkQuality[myUserId];

    return myNetworkQuality?.uplink < 2;
  };

  const { mainContainerParticipants, floatingContainerParticipant } = useMemo(() => {
    const mainContainerParticipants: Participant[] = [];
    let floatingContainerParticipant: Participant | undefined;

    participants.forEach((participant) => {
      const myUserId = zoomClient?.getSessionInfo().userId;

      if (participant.userId === myUserId) {
        if ([2, 3].includes(participants.length)) {
          floatingContainerParticipant = participant;
        } else {
          mainContainerParticipants.push(participant);
        }
      } else {
        mainContainerParticipants.push(participant);
      }
    });

    return { mainContainerParticipants, floatingContainerParticipant };
  }, [participants]);

  const pastMainContainerParticipants = usePrevious(mainContainerParticipants);
  const pastFloatingContainerParticipant = usePrevious(floatingContainerParticipant);

  const {
    participantInPiP,
    setParticipantInPiP,
    getPiPParticipants,
    startPictureInPictureHandler,
  } = usePiP({
    participants,
    videoListRef,
  });

  useEffect(() => {
    if (
      !stream ||
      !pastMainContainerParticipants ||
      (!pastMainContainerParticipants.length && !mainContainerParticipants.length)
    )
      return;

    const { addedParticipants, removedParticipants } = findParticipantsDifference(
      pastMainContainerParticipants.filter((participant) => participant.bVideoOn),
      mainContainerParticipants.filter((participant) => participant.bVideoOn)
    );

    const isRemoved =
      pastFloatingContainerParticipant?.bVideoOn && !floatingContainerParticipant?.bVideoOn;
    const isAdded =
      !pastFloatingContainerParticipant?.bVideoOn && floatingContainerParticipant?.bVideoOn;

    (async () => {
      for (const participant of removedParticipants) {
        try {
          if (
            participant.userId.toString() === participantInPiP &&
            document.pictureInPictureElement
          ) {
            try {
              document.exitPictureInPicture();
              setParticipantInPiP(undefined);
              setIsPiPActive(false);
            } catch (error) {
              console.log(error);
            }
          }
          await stream.detachVideo(participant.userId);
        } catch (error) {
          console.log({ error });
        }
      }

      if (isRemoved) {
        try {
          await stream.detachVideo(pastFloatingContainerParticipant.userId);
        } catch (error) {
          console.log({ error });
        }
      }

      for (const participant of addedParticipants) {
        try {
          await stream.attachVideo(
            participant.userId,
            VideoQuality.Video_720P,
            videoListRef.current[`${participant.userId}`]
          );
        } catch (error) {
          console.log({ error });
        }
      }

      if (isAdded) {
        try {
          await stream.attachVideo(
            floatingContainerParticipant.userId,
            VideoQuality.Video_720P,
            videoListRef.current[`${floatingContainerParticipant.userId}`]
          );
        } catch (error) {
          console.log({ error });
        }
      }
    })();
  }, [stream, mainContainerParticipants, floatingContainerParticipant]);

  const { audioQuality, videoQuality, shareQuality } = useStatisticsListener({ connectionState });

  const isPageOpened = pageName !== undefined;

  const memoizedVideoContent = useMemo(() => {
    return (
      <VideoContent
        isPiPActive={isPiPActive}
        participantInPiP={participantInPiP}
        isPageOpened={isPageOpened}
        connectionState={connectionState}
        participantsLength={participantsLength}
        ownId={zoomClient?.getSessionInfo().userId}
        isViewingScreenShare={isViewingScreenShare}
        isSharing={isSharing}
        isScreenShareLoading={isScreenShareLoading}
        isStartShareScreenWithVideoElement={stream?.isStartShareScreenWithVideoElement()}
        mainContainerParticipants={mainContainerParticipants}
        floatingContainerParticipant={floatingContainerParticipant}
        thumbsUpList={thumbsUpList}
        speakingParticipants={speakingParticipants}
        raisedHands={raisedHands}
        screenShareVideoContainerRef={screenShareVideoContainerRef}
        screenShareCanvasContainerRef={screenShareCanvasContainerRef}
        screenShareViewCanvasContainerRef={screenShareViewCanvasContainerRef}
        onSetVideoRef={setVideoPlayerRef}
      />
    );
  }, [
    isPiPActive,
    participantInPiP,
    isPageOpened,
    connectionState,
    participantsLength,
    zoomClient?.getSessionInfo().userId,
    isViewingScreenShare,
    isSharing,
    isScreenShareLoading,
    stream?.isStartShareScreenWithVideoElement(),
    mainContainerParticipants,
    floatingContainerParticipant,
    thumbsUpList,
    speakingParticipants,
    raisedHands,
  ]);

  if (isMeetingLoading) {
    return <VideoMeetingLoading />;
  }

  return (
    <section className="flex flex-col justify-between h-full px-3 laptop:h-screen laptop:p-9">
      <div className="flex items-center justify-center flex-auto gap-4 relative h-[calc(100%-48px)] laptop:h-[calc(100%-72px)]">
        {/* mobile only meeting actions */}
        {memoizedVideoContent}
        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          handleShowSidebar={showSidebarHandler}
          handleCloseSidebar={closeSidebarHandler}
          isMentor={isMentor}
          unreadCount={unreadCount}
          className={mergeClassNames(
            'flex flex-row tablet:flex-col absolute right-0 top-1 gap-2 laptop:hidden'
          )}
          isNetworkWarning={getIsNetworkWarning()}
        />
        {showSidebar && (
          <StatisticsContext.Provider value={{ audioQuality, videoQuality, shareQuality }}>
            <VideoSidebar
              connectionState={connectionState}
              networkQuality={networkQuality}
              isScreenBeingShared={isSharing || isViewingScreenShare}
              chatMessages={chatMessages}
              handleCloseSidebar={closeSidebarHandler}
              pageName={pageName ?? 'chat'}
              unreadCount={unreadCount}
              onUnreadCountChange={setUnreadCount}
            />
          </StatisticsContext.Provider>
        )}

        {thumbsUpList.map((thumbsUp) => (
          <ThumbsUpLottie key={thumbsUp.timestamp} userName={thumbsUp.senderName} />
        ))}
      </div>

      <section
        className={mergeClassNames(
          'relative flex items-center justify-center mt-4 mb-6 laptop:mt-9 laptop:mb-0 laptop:justify-between',
          { 'laptop:mt-14': participantsLength === 2 && !(isViewingScreenShare || isSharing) }
        )}
      >
        <h1 className="hidden absolute left-0 tablet:flex laptop:static">
          {meetingDetails?.name || ''}
        </h1>

        {params.get('inMeeting') === 'true' &&
          connectionState === ConnectionState.Connected &&
          participantsLength > 0 && (
            <VideoActionButtons
              isPiPActive={isPiPActive}
              isHandRaised={isHandRaised}
              isCameraButtonLoading={isCameraStateSwitchLoading}
              isMicrophoneButtonLoading={isMicrophoneStateSwitchLoading}
              isShareScreenDisabled={isViewingScreenShare}
              isPiPDisabled={participantsLength <= 1 || !getPiPParticipants()?.[0]?.bVideoOn}
              setActiveModal={setActiveModal}
              onCameraButtonClick={handleCameraButtonClick}
              startPiP={startPictureInPictureHandler}
              onMicrophoneButtonClick={handleMicrophoneButtonClick}
              onRaiseHandClick={(senderId) =>
                raiseHandClickHandler(senderId, !raisedHands[senderId], true)
              }
              setIsPiPActive={setIsPiPActive}
              onThumbsUpClick={thumbsUpClickHandler}
              onShareScreenClick={shareScreenClickHandler}
            />
          )}

        {showFeedbackModal && (
          <FeedbackModal
            openedOnMeetingEnd
            isOpen={true}
            meetingId={meetingId}
            onClose={() => setShowFeedbackModal(false)}
          />
        )}

        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          unreadCount={unreadCount}
          handleShowSidebar={showSidebarHandler}
          handleCloseSidebar={closeSidebarHandler}
          isMentor={isMentor}
          className="hidden laptop:flex"
          isNetworkWarning={getIsNetworkWarning()}
        />
      </section>
      <VideoMeetingModals
        micList={micList}
        virtualBackground={virtualBackground}
        cameraList={cameraList}
        backgroundSuppression={backgroundSuppression}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        setBackgroundSuppression={setBackgroundSuppression}
        setVirtualBackground={setVirtualBackground}
        onCloseModal={closeModalHandler}
      />
    </section>
  );
};

export default VideoMeeting;
