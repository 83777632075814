import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal/Modal';
import { ZoomContext } from '@modules/zoom/contexts/ZoomContext';
import { detectDeviceType } from '@shared/utils/string';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import VideoMeetingLoading from '../VideoMeeting/VideoMeetingLoading';
import VideoMeetingDetails from './VideoMeetingDetails';
import VideoMeetingPreview from './VideoMeetingPreview';

type Props = {
  setIsWaitingRoom: (isWaitingRoom: boolean) => void;
};

const VideoWaitingRoom = (props: Props) => {
  const { setIsWaitingRoom } = props;
  const [showMobileWarning, setShowMobileWarning] = useState<boolean>(
    detectDeviceType() === 'Mobile'
  );

  const { isMeetingLoading } = useContext(ZoomContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    params.delete('inMeeting');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, []);

  if (isMeetingLoading) {
    return <VideoMeetingLoading />;
  }

  return (
    <section
      className="
        max-w-screen-largeLaptop m-auto flex flex-col gap-12
        tablet:p-14
        laptop:flex-row laptop:h-screen laptop:items-center laptop:gap-[clamp(4rem,7.2vw,6.5rem)]
        largeLaptop:pl-[4.875rem] largeLaptop:pr-[9.5rem]
      "
    >
      <VideoMeetingPreview />
      <VideoMeetingDetails setIsWaitingRoom={setIsWaitingRoom} />
      <Modal isOpen={showMobileWarning} onClose={() => setShowMobileWarning(false)} hasCloseIcon>
        <Modal.Header className="text-xl font-semibold text-white">Warning</Modal.Header>
        <Modal.Body className="flex flex-col justify-center items-center text-center">
          <p className="py-10 text-white">
            Dear participant, please keep in mind that Screen Sharing will not be working on mobile
            and tablet devices. We strongly recommend using a laptop or desktop for the best
            experience.
          </p>
          <Button
            variant="primary"
            buttonText="OK!"
            className="h-8"
            onClick={() => setShowMobileWarning(false)}
          />
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default VideoWaitingRoom;
