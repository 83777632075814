import { ReactComponent as AddIcon } from '@assets/icons/V4/add.svg';
import { ReactComponent as FullscreenIcon } from '@assets/icons/V4/fullscreen.svg';
import { ReactComponent as RemoveIcon } from '@assets/icons/V4/remove.svg';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import IconButton from '@components/V4/IconButton';
import useCanvasAspectRatio from '@modules/zoom/hooks/useCanvasAspectRatio';
import useCanvasZoomPan from '@modules/zoom/hooks/useCanvasZoomPan';
import useElementSize from '@modules/zoom/hooks/useElementSize';
import useShowCanvasButtons from '@modules/zoom/hooks/useShowCanvasButtons';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { Ref, RefObject, useEffect, useRef } from 'react';

interface ScreenShareCanvasProps extends BasicProps {
  isViewingScreenShare: boolean;
  isSharing: boolean;
  isScreenShareLoading: boolean;
  isStartShareScreenWithVideoElement?: boolean;
  screenShareVideoContainerRef: Ref<HTMLVideoElement>;
  screenShareCanvasContainerRef: RefObject<HTMLCanvasElement>;
  screenShareViewCanvasContainerRef: RefObject<HTMLCanvasElement>;
}

const ScreenShareCanvas = (props: ScreenShareCanvasProps) => {
  const {
    isViewingScreenShare,
    isSharing,
    isScreenShareLoading,
    isStartShareScreenWithVideoElement,
    screenShareVideoContainerRef,
    screenShareCanvasContainerRef,
    screenShareViewCanvasContainerRef,
  } = props;

  const parentRef = useRef<HTMLDivElement>(null);

  const showCanvasButtons = useShowCanvasButtons(parentRef);

  const { width, height } = useElementSize(parentRef);

  const ratio = useCanvasAspectRatio();

  const {
    transformStyle,
    pointerDownHandler,
    pointerMoveHandler,
    pointerUpHandler,
    doubleClickHandler,
    zoomIn,
    zoomOut,
  } = useCanvasZoomPan({
    containerWidth: width,
    containerHeight: height,
    contentWidth: width,
    contentHeight: height,
    scaleStep: 0.2,
    doubleClickToReset: true,
  });

  useEffect(() => {
    //Workaround for chrome on mac exit full screen green button
    const handleResize = () => {
      setTimeout(async () => {
        const isWindowFullscreen =
          window.innerWidth === window.screen.width && window.innerHeight === window.screen.height;
        const isStillFullscreen = !!document.fullscreenElement;

        if (!isWindowFullscreen && isStillFullscreen) {
          try {
            await document.exitFullscreen();
          } catch (error) {
            console.log(error);
          }
        }
      }, 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fullscreenClickHandler = async () => {
    try {
      await screenShareViewCanvasContainerRef.current?.requestFullscreen();
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div
      className={mergeClassNames(
        'hidden relative h-full max-h-full w-full items-center rounded-lg',
        {
          flex: isViewingScreenShare || isSharing,
          'max-w-max': !isScreenShareLoading,
          'max-w-[calc(100%-280px)]': isScreenShareLoading,
        }
      )}
    >
      {isScreenShareLoading && (
        <LoadingSpinner
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          size="large"
          text="Loading Screen Share"
        />
      )}

      <video
        disablePictureInPicture
        ref={screenShareVideoContainerRef}
        className={mergeClassNames('hidden max-h-full w-full max-w-min rounded-lg', {
          flex: isSharing && !!isStartShareScreenWithVideoElement,
        })}
      />

      <canvas
        ref={screenShareCanvasContainerRef}
        className={mergeClassNames('hidden max-h-full w-full max-w-max rounded-lg object-contain', {
          flex: isSharing && !isStartShareScreenWithVideoElement,
        })}
      />
      <div
        ref={parentRef}
        style={{ aspectRatio: ratio }}
        className={mergeClassNames('hidden relative max-h-full max-w-full overflow-hidden', {
          flex: isViewingScreenShare,
          'h-full': isScreenShareLoading,
        })}
      >
        <div
          className={mergeClassNames(
            'opacity-0 flex transition-all absolute gap-2 bottom-4 right-4 z-10',
            {
              'opacity-100': !isScreenShareLoading && showCanvasButtons,
            }
          )}
        >
          <IconButton
            isActive
            size="large"
            icon={<RemoveIcon className="text-white" />}
            onClick={zoomOut}
          />
          <IconButton
            isActive
            size="large"
            icon={<AddIcon className="text-white" />}
            onClick={zoomIn}
          />
        </div>
        <IconButton
          isActive
          className={mergeClassNames('absolute top-4 right-4 opacity-0 z-10', {
            'opacity-100': !isScreenShareLoading && showCanvasButtons,
          })}
          size="large"
          icon={<FullscreenIcon className="text-white" />}
          onClick={fullscreenClickHandler}
        />

        <canvas
          className="max-h-full w-max max-w-full rounded-lg object-contain fullscreen:bg-black"
          ref={screenShareViewCanvasContainerRef}
          onPointerDown={pointerDownHandler}
          onPointerMove={pointerMoveHandler}
          onPointerUp={pointerUpHandler}
          onDoubleClick={doubleClickHandler}
          style={{
            ...transformStyle,
          }}
        />
      </div>
    </div>
  );
};

export default ScreenShareCanvas;
