import { COLORS } from '../v3/global';

const RADIAN = Math.PI / 180;

export const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      color={COLORS.TEXT_PRIMARY}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      className="nps-problems-pie-chart-label"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
