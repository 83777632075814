import NextMeetingMentor from '@components/V3/NextMeetingsMentor/NextMeetingMentor';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { useOngoingMeetings } from '@actions';
import style from '@styles/views/home.module.scss';
import NextMeetingStudent from '../Student/NextMeetingsStudent/NextMeetingStudent';
import SkeletonBox from '@components/V4/SkeletonBox';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { MEETINGS_PAGE_LIMIT } from '@shared/constants/common';

interface OngoingMeetingsSectionProps {
  isMentor: boolean;
}

const OngoingMeetingsSection = (props: OngoingMeetingsSectionProps) => {
  const { isMentor } = props;
  const { data, isLoading } = useOngoingMeetings();
  const { data: meetings } = data || {};

  if (!meetings?.length && !isLoading) {
    return null;
  }

  return (
    <>
      <div className="mb-8">
        <SectionTitle title="Ongoing Meetings" />
      </div>
      {isLoading ? (
        <div className="flex flex-col gap-5 mb-14">
          {[...Array(MEETINGS_PAGE_LIMIT).keys()].map((_, index) => (
            <SkeletonBox className="h-18 w-full" key={index} />
          ))}
        </div>
      ) : (
        <div className={mergeClassNames(style.meetings, style.ongoing)}>
          {isMentor && meetings?.length
            ? meetings.map((meeting) => <NextMeetingMentor key={meeting._id} meeting={meeting} />)
            : meetings?.map((meeting) => (
                <NextMeetingStudent key={meeting._id} meeting={meeting} />
              ))}
        </div>
      )}
    </>
  );
};

export default OngoingMeetingsSection;
