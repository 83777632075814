import { mentorApis, usersApis } from '@shared/apis';
import { UserRole } from '@shared/enums';
import {
  genericMutation,
  genericMutationWithId,
  genericMutationWithParams,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useUpdateMyMentorData = genericMutationWithParams<usersApis.UpdateMyMentorData>(
  usersApis.updateMyMentorData,
  mentorApis.getAllCoursesCanTeach.endpoint
);

export const useUpdateMentorData = genericMutationWithId<usersApis.UpdateMentorData>(
  usersApis.updateMentorData
);

export const useStudentDetails = genericQueryWithId<usersApis.GetStudentDetails>(
  usersApis.getStudentDetails
);

export const useSearchUsers = genericQueryWithParams<usersApis.SearchUsers>(usersApis.searchUsers);

export const useSearchUsersByFullName = genericQueryWithParams<usersApis.SearchUsersByFullName>(
  usersApis.searchUsersByFullName
);

export const useMentors = (extraSearchQuery: any) => {
  return useSearchUsers({
    limit: 500,
    roles: [UserRole.Mentor],
    ...extraSearchQuery,
  });
};

export const useUserDetails = genericQueryWithId<usersApis.GetUserDetails>(
  usersApis.getUserDetails
);

export const useUserNotes = genericQueryWithId<usersApis.GetUserNotes>(usersApis.getUserNotes);

export const useUpdateUserNote = genericMutationWithId<usersApis.UpdateUserNote>(
  usersApis.updateUserNote,
  usersApis.getUserNotes.endpoint
);

export const useUpdateUser = genericMutationWithId<usersApis.UpdateUser>(
  usersApis.updateUser,
  usersApis.getStudentDetails.endpoint
);

export const useGetAllMentors = genericQueryWithParams<usersApis.GetAllMentors>(
  usersApis.getAllMentors
);

export const useGetAllStudents = genericQueryWithParams<usersApis.GetAllStudents>(
  usersApis.getAllStudents
);

export const useSearchStudents = genericQueryWithParams<usersApis.SearchStudents>(
  usersApis.searchStudents
);

export const useGetUserProfile = genericQueryWithId<usersApis.GetUserProfile>(
  usersApis.getUserProfile
);

export const useSaveUserProfile = genericMutationWithId<usersApis.SaveUserProfile>(
  usersApis.saveUserProfile,
  '/users/:id/profile',
  mentorApis.searchMentors.endpoint,
  usersApis.searchStudents.endpoint
);

export const useGetStudentStatistics = genericQuery<usersApis.GetStudentStatistics>(
  usersApis.getStudentStatistics
);

export const useSearchUsersV2 = genericQueryWithParams<usersApis.SearchUsersV2>(
  usersApis.searchUsersV2
);

export const useEditUserPermissions = genericMutationWithId<usersApis.EditUserPermissions>(
  usersApis.editUserPermissions
);

export const useGetUserPermissions = genericQueryWithId<usersApis.GetUserPermissions>(
  usersApis.getUserPermissions
);

export const useCheckIfUserExists = genericQueryWithParams<usersApis.IsUserOrParentEmailRegistered>(
  usersApis.isUserOrParentEmailRegistered
);

export const useAddVirtualBackground = genericMutation<usersApis.AddVirtualBackground>(
  usersApis.addVirtualBackground,
  usersApis.addVirtualBackground.endpoint
);

export const useDeleteVirtualBackground = genericMutation<usersApis.DeleteVirtualBackground>(
  usersApis.deleteVirtualBackground,
  usersApis.deleteVirtualBackground.endpoint
);

export const useGetVirtualBackgrounds = genericQuery<usersApis.GetVirtualBackgrounds>(
  usersApis.getVirtualBackgrounds
);

export const useGetVideoSettings = genericQuery<usersApis.GetVideoSettings>(
  usersApis.getVideoSettings
);

export const useUpdateVideoSettings = genericMutation<usersApis.UpdateVideoSettings>(
  usersApis.updateVideoSettings
);

export const useGetTimezonesList = genericQuery<usersApis.GetTimezonesList>(
  usersApis.getTimezonesList
);

export const useDeleteAdmissionDocument = genericMutationWithId<usersApis.DeleteAdmissionDocument>(
  usersApis.deleteAdmissionDocument,
  usersApis.deleteAdmissionDocument.endpoint,
  usersApis.getUserProfile.endpoint
);

export const useGetMentorStatisticsByMentorStatus =
  genericQuery<usersApis.GetMentorStatisticsByMentorStatus>(
    usersApis.getMentorStatisticsByMentorStatus
  );

export const useGetMentorsByStatus = genericQueryWithParams<usersApis.GetMentorsByStatus>(
  usersApis.getMentorsByStatus
);

export const useGetMentorStatisticsByCoursesCanTeach =
  genericQueryWithParams<usersApis.GetMentorStatisticsByCoursesCanTeach>(
    usersApis.getMentorStatisticsByCoursesCanTeach
  );

export const useGetMentorsByCoursesCanTeach =
  genericQueryWithParams<usersApis.GetMentorsByCoursesCanTeach>(
    usersApis.getMentorsByCoursesCanTeach
  );

export const useGetUserStatisticsByProfileCompletion =
  genericQueryWithParams<usersApis.GetUserStatisticsByProfileCompletion>(
    usersApis.getUserStatisticsByProfileCompletion
  );

export const useGetUsersByProfileCompletion =
  genericQueryWithParams<usersApis.GetUsersByProfileCompletion>(
    usersApis.getUsersByProfileCompletion
  );

export const useGetMentorStatisticsByUniversity =
  genericQueryWithParams<usersApis.GetMentorStatisticsByUniversity>(
    usersApis.getMentorStatisticsByUniversity
  );

export const useGetMentorsByUniversity = genericQueryWithParams<usersApis.GetMentorsByUniversity>(
  usersApis.getMentorsByUniversity
);

export const useGetMentorStatisticsByUniversityRegion =
  genericQueryWithParams<usersApis.GetMentorStatisticsByUniversityRegion>(
    usersApis.getMentorStatisticsByUniversityRegion
  );

export const useGetMentorsByUniversityRegion =
  genericQueryWithParams<usersApis.GetMentorsByUniversityRegion>(
    usersApis.getMentorsByUniversityRegion
  );

export const useGetUserStatisticsByCountry =
  genericQueryWithParams<usersApis.GetUserStatisticsByCountry>(
    usersApis.getUserStatisticsByCountry
  );
export const useGetUsersByCountry = genericQueryWithParams<usersApis.GetUsersByCountry>(
  usersApis.getUsersByCountry
);

export const useGetUserStatisticsByCity = genericQueryWithParams<usersApis.GetUserStatisticsByCity>(
  usersApis.getUserStatisticsByCity
);
export const useGetUsersByCity = genericQueryWithParams<usersApis.GetUsersByCity>(
  usersApis.getUsersByCity
);

export const useGetStudentStatisticsByUniversity =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversity>(
    usersApis.getStudentStatisticsByUniversity
  );

export const useGetStudentsByUniversity = genericQueryWithParams<usersApis.GetStudentsByUniversity>(
  usersApis.getStudentsByUniversity
);

export const useGetStudentStatisticsByUniversityOffersAccepted =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversityOffersAccepted>(
    usersApis.getStudentStatisticsByUniversityOffersAccepted
  );

export const useGetStudentsByUniversityOffersAccepted =
  genericQueryWithParams<usersApis.GetStudentsByUniversityOffersAccepted>(
    usersApis.getStudentsByUniversityOffersAccepted
  );

export const useGetStudentStatisticsByUniversityApplicationsOpened =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversityApplicationsOpened>(
    usersApis.getStudentStatisticsByUniversityApplicationsOpened
  );
export const useGetStudentsByUniversityApplicationsOpened =
  genericQueryWithParams<usersApis.GetStudentsByUniversityApplicationsOpened>(
    usersApis.getStudentsByUniversityApplicationsOpened
  );

export const useGetStudentStatisticsByUniversityApplicationsOpenedRegion =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversityApplicationsOpenedRegion>(
    usersApis.getStudentStatisticsByUniversityApplicationsOpenedRegion
  );
export const useGetStudentsByUniversityApplicationsOpenedRegion =
  genericQueryWithParams<usersApis.GetStudentsByUniversityApplicationsOpenedRegion>(
    usersApis.getStudentsByUniversityApplicationsOpenedRegion
  );

export const useGetStudentStatisticsByUniversityApplicationsOpenedCountry =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversityApplicationsOpenedCountry>(
    usersApis.getStudentStatisticsByUniversityApplicationsOpenedCountry
  );
export const useGetStudentsByUniversityApplicationsOpenedCountry =
  genericQueryWithParams<usersApis.GetStudentsByUniversityApplicationsOpenedCountry>(
    usersApis.getStudentsByUniversityApplicationsOpenedCountry
  );

export const useGetStudentStatisticsByUniversityAdmissionRate =
  genericQueryWithParams<usersApis.GetStudentStatisticsByUniversityAdmissionRate>(
    usersApis.getStudentStatisticsByUniversityAdmissionRate
  );
export const useGetStudentsByUniversityAdmissionRate =
  genericQueryWithParams<usersApis.GetStudentsByUniversityAdmissionRate>(
    usersApis.getStudentsByUniversityAdmissionRate
  );

export const useGetStudentStatisticsByApplicationStatus =
  genericQueryWithParams<usersApis.GetStudentStatisticsByApplicationStatus>(
    usersApis.getStudentStatisticsByApplicationStatus
  );
export const useGetStudentsByApplicationStatus =
  genericQueryWithParams<usersApis.GetStudentsByApplicationStatus>(
    usersApis.getStudentsByApplicationStatus
  );

export const useGetStudentStatisticsByEnrollments =
  genericQueryWithParams<usersApis.GetStudentStatisticsByEnrollments>(
    usersApis.getStudentStatisticsByEnrollments
  );
export const useGetStudentsByEnrollments =
  genericQueryWithParams<usersApis.GetStudentsByEnrollments>(usersApis.getStudentsByEnrollments);
