import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import SessionModalFormWrapper from '@modules/Admin/components/DetailsModal/DetailsModalFormWrapper';
import { useSaveUserProfile } from '@actions';
import useToast from 'apps/agora/src/hooks/useToast';
import { getNumericEnumValues } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import moment from 'moment';
import { useEffect } from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import { Experience } from '@shared/common';
import { ACTIVITY_TYPE, EMPLOYMENT_TYPE, EXPERIENCE_TYPE } from '@shared/constants';
import { ExperienceType, EmploymentType, ActivityType } from '@shared/enums';

interface WorkExperienceSectionFormProps extends BasicProps {
  userId: string;
  isModeAddNew?: boolean;
  workExperienceData?: Experience;
  onCloseEditForm: () => void;
}
const formDataSchema = z.object({
  positionTitle: z.string().min(1, { message: 'Please enter a valid school name.' }),
  employer: z.string().min(1, { message: 'Please enter a valid name.' }),
  experienceType: z
    .number()
    .min(0)
    .refine((value) => value in ExperienceType, {
      message: 'Please enter a valid experience type.',
    }),
  employmentType: z
    .number()
    .min(0)
    .refine((value) => value in EmploymentType || value in ACTIVITY_TYPE, {
      message: 'Please enter a valid employment type.',
    }),
  startDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid date.' }),
  description: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const WorkExperienceSectionForm = (props: WorkExperienceSectionFormProps) => {
  const { userId, isModeAddNew = false, workExperienceData, className, onCloseEditForm } = props;

  const toast = useToast();

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      toast.success('Successfully updated your profile.');
      onCloseEditForm();
    },
    onError: () => {
      toast.error('Profile could not be saved.');
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    resetField,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      positionTitle: workExperienceData?.positionTitle,
      employer: workExperienceData?.employer ?? workExperienceData?.institution,
      employmentType: workExperienceData?.employmentType ?? workExperienceData?.activityType,
      startDate: workExperienceData?.startDate,
      endDate: workExperienceData?.endDate,
      description: workExperienceData?.description,
      experienceType: workExperienceData?.experienceType,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } = useController({
    name: 'startDate',
    control,
  });

  const { field: experienceTypeField, fieldState: experienceTypeFieldState } = useController({
    name: 'experienceType',
    control,
  });

  const experienceTypeValue = useWatch({ control, name: 'experienceType' });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const { field: employmentTypeField, fieldState: employmentTypeFieldState } = useController({
    name: 'employmentType',
    control,
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      let experience;
      experience = {
        _id: workExperienceData?._id,
        positionTitle: data.positionTitle,
        startDate: data.startDate,
        endDate: data.endDate,
        description: data.description ?? '',
        experienceType: data.experienceType,
      };

      if (data.experienceType === ExperienceType.Work) {
        experience = {
          ...experience,
          employer: data.employer,
          employmentType: data.employmentType,
        };
      } else {
        experience = {
          ...experience,
          institution: data.employer,
          activityType: data.employmentType,
        };
      }
      updateUserProfile({
        experiences: [experience],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  const workTypeOptions = {
    Work: {
      options: getNumericEnumValues(EmploymentType)?.map((employment) => ({
        label: EMPLOYMENT_TYPE[employment],
        value: employment,
      })),

      label: 'Employment Type',
    },
    Extra: {
      options: getNumericEnumValues(ActivityType)?.map((activity) => ({
        label: ACTIVITY_TYPE[activity],
        value: activity,
      })),

      label: 'Activity Type',
    },
  };

  const currentType = experienceTypeField.value === ExperienceType.Work ? 'Work' : 'Extra';
  const { options: currentOptions, label: currentLabel } = workTypeOptions[currentType];

  const experienceTypeFieldSelectedHandler = (value: number) => {
    if (value !== experienceTypeValue) {
      //TODO find a permanent solution for this
      resetField('employmentType', { defaultValue: null as unknown as number });
    }
    experienceTypeField.onChange(value);
  };

  return (
    <SessionModalFormWrapper
      contentClassName={className}
      title={isModeAddNew ? 'Add Experience' : 'Edit Experience'}
      isLoading={isLoading}
      onCancel={onCloseEditForm}
      submitHandler={submitHandler}
      submitButtonText={isModeAddNew ? 'Create Experience' : 'Save Experience'}
    >
      <Select
        options={getNumericEnumValues(ExperienceType)?.map((option) => ({
          label: EXPERIENCE_TYPE[option],
          value: option,
        }))}
        onSelect={(value) => experienceTypeFieldSelectedHandler(value as number)}
        value={experienceTypeField.value}
        onBlur={experienceTypeField.onBlur}
        isRequired
        label="Experience Type"
        isValid={!experienceTypeFieldState.error}
        isTouched={experienceTypeFieldState.isTouched}
        errorText={experienceTypeFieldState.error?.message}
        size="large"
        allowClear={false}
        autoFocus
      />
      <Select
        options={currentOptions}
        onSelect={(value) => employmentTypeField.onChange(value)}
        value={employmentTypeField.value}
        onBlur={employmentTypeField.onBlur}
        isRequired
        label={currentLabel}
        isValid={!employmentTypeFieldState.error}
        isTouched={employmentTypeFieldState.isTouched}
        errorText={employmentTypeFieldState?.error?.message}
        size="large"
        allowClear={false}
      />

      <Input
        type="text"
        id={'positionTitle'}
        label={'Position Name'}
        isRequired
        isTouched={touchedFields.positionTitle}
        isValid={!errors.positionTitle}
        errorText={errors.positionTitle?.message}
        {...register('positionTitle')}
      />
      <Input
        type="text"
        id={'employer'}
        label={currentType === 'Work' ? 'Employer' : 'Institution/Organisation'}
        isRequired
        isTouched={touchedFields.employer}
        isValid={!errors.employer}
        errorText={errors.employer?.message}
        {...register('employer')}
      />

      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startDateField.value ? moment(startDateField.value) : undefined}
          onChange={(value) => startDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={startDateField.onBlur}
          allowClear={false}
          isRequired
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) => endDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={endDateField.onBlur}
          allowClear={false}
          isRequired
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
      <TextArea id={'description'} label={'Description'} {...register('description')} />
    </SessionModalFormWrapper>
  );
};

export default WorkExperienceSectionForm;
