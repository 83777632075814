import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import Button from '@components/V4/Button';
import { ConnectionState, Participant, VideoPlayer as VideoPlayerType } from '@zoom/videosdk';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { BasicProps } from 'apps/agora/src/utils/types';
import { Ref, RefObject, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ScreenShareCanvas from './ScreenShareCanvas';
import VideoPlayer from './VideoPlayer';
import VideoPlayerContainer from './VideoPlayerContainer';

interface VideoContentProps extends BasicProps {
  connectionState: ConnectionState;
  ownId?: number;
  isPageOpened?: boolean;
  isViewingScreenShare: boolean;
  isSharing: boolean;
  isLoading?: boolean;
  isScreenShareLoading: boolean;
  participantsLength?: number;
  isStartShareScreenWithVideoElement?: boolean;
  isPiPActive: boolean;
  participantInPiP?: string;
  mainContainerParticipants: Participant[];
  floatingContainerParticipant?: Participant;
  thumbsUpList: { senderId: number; senderName: string; timestamp: number }[];
  speakingParticipants: Record<number, boolean>;
  raisedHands: Record<number, boolean>;
  screenShareVideoContainerRef: Ref<HTMLVideoElement>;
  screenShareViewCanvasContainerRef: RefObject<HTMLCanvasElement>;
  screenShareCanvasContainerRef: RefObject<HTMLCanvasElement>;
  onSetVideoRef: (userId: number, element: VideoPlayerType | null) => void;
}

const VideoContent = (props: VideoContentProps) => {
  const {
    ownId,
    isSharing,
    isLoading,
    isPiPActive,
    participantInPiP,
    raisedHands,
    isPageOpened,
    mainContainerParticipants,
    floatingContainerParticipant,
    thumbsUpList,
    connectionState,
    speakingParticipants,
    isViewingScreenShare,
    isScreenShareLoading,
    isStartShareScreenWithVideoElement,
    screenShareVideoContainerRef,
    screenShareCanvasContainerRef,
    screenShareViewCanvasContainerRef,
    onSetVideoRef,
  } = props;

  const totalParticipantsLength =
    mainContainerParticipants.length + (floatingContainerParticipant ? 1 : 0);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const getVideoSize = (userId: number) => {
    const isOwnVideo = userId === ownId;
    const isOwnIdNaN = Number.isNaN(ownId);
    const areMultipleParticipants = totalParticipantsLength > 1 && totalParticipantsLength < 4;

    if (
      (areMultipleParticipants && (isOwnVideo || isOwnIdNaN)) ||
      isSharing ||
      isViewingScreenShare
    ) {
      return 'small';
    }

    return 'default';
  };

  const getHasThumbsUp = (senderId: number) => {
    const index = thumbsUpList.findIndex((thumbsUp) => thumbsUp.senderId === senderId);

    return index > -1;
  };

  const memoizedScreenShareCanvas = useMemo(() => {
    return (
      <ScreenShareCanvas
        isViewingScreenShare={isViewingScreenShare}
        isSharing={isSharing}
        isScreenShareLoading={isScreenShareLoading}
        isStartShareScreenWithVideoElement={isStartShareScreenWithVideoElement}
        screenShareVideoContainerRef={screenShareVideoContainerRef}
        screenShareCanvasContainerRef={screenShareCanvasContainerRef}
        screenShareViewCanvasContainerRef={screenShareViewCanvasContainerRef}
      />
    );
  }, [isViewingScreenShare, isSharing, isScreenShareLoading, isStartShareScreenWithVideoElement]);

  if (params.get('inMeeting') !== 'true') {
    return (
      <div className="flex flex-col items-center">
        <h2 className="text-customGrey font-bold text-2xl font-raleway">This meeting has ended.</h2>

        <Link className="mt-4" to="/home">
          <Button buttonText="Go Home" size="large" />
        </Link>
      </div>
    );
  }

  if (connectionState === ConnectionState.Fail) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <h1 className="text-xsm leading-4">
          Couldn't reconnect. Please reload the page and re-enter the meeting.
        </h1>
        <Button
          className="w-28"
          size="large"
          buttonText="Reload Page"
          onClick={() => window.location.reload()}
        />
      </section>
    );
  }

  if (connectionState === ConnectionState.Reconnecting) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <LoadingSpinner />
        <h1 className="text-xsm leading-4">
          Encountered some network issues. Trying to reconnect...
        </h1>
      </section>
    );
  }

  if (totalParticipantsLength === 0 || isLoading) {
    return (
      <section className="flex flex-col items-center justify-center gap-4 w-full h-[calc(100vh-56px)] laptop:h-full">
        <LoadingSpinner />
        <h1 className="text-xsm leading-4">Loading...</h1>
      </section>
    );
  }

  return (
    <div
      id="video-content-wrapper"
      className={mergeClassNames('relative flex w-full h-full items-center justify-center gap-4', {
        'max-w-[calc(100%-22.5rem)]': isPageOpened,
      })}
    >
      {memoizedScreenShareCanvas}
      <div
        className={mergeClassNames({
          'flex flex-col gap-2 tablet-gap-0 short:flex-row tablet:flex-row justify-center laptop:h-auto laptop:max-w-full w-full':
            !(isSharing || isViewingScreenShare),
          'hidden laptop:flex laptop:flex-col laptop:justify-center laptop:min-w-[231px] laptop:gap-2':
            isSharing || isViewingScreenShare,
          'h-full': totalParticipantsLength <= 2,
        })}
      >
        {!!floatingContainerParticipant && (
          <VideoPlayerContainer
            containerClassName={mergeClassNames(
              'tablet:aspect-video tablet:z-50 short:relative short:bottom-0 short:right-0 short:items-center',
              {
                'tablet:absolute tablet:-bottom-2.5 tablet:right-10': !(
                  isSharing || isViewingScreenShare
                ),
                'max-h-[calc(100vh-160px)] h-full w-auto max-w-[calc(100vw-160px)]':
                  totalParticipantsLength === 1 && !(isSharing || isViewingScreenShare),
                'short:max-w-[calc(31vw)] w-full': totalParticipantsLength === 3,
                'short:max-w-[calc(50vw-16px)] w-full': totalParticipantsLength === 2,
                'short:h-auto short:w-full tablet:h-[130px] tablet:w-auto':
                  (totalParticipantsLength > 1 && !(isSharing || isViewingScreenShare)) ||
                  isSharing ||
                  isViewingScreenShare,
              }
            )}
            className="flex tablet:grid"
          >
            <VideoPlayer
              id={`video-player-container-${floatingContainerParticipant.userId}`}
              containerClassName={mergeClassNames({
                'max-h-[calc(100vh-160px)]': totalParticipantsLength === 1,
                'max-w-[calc(80vw)] h-auto short:h-auto short:w-full tablet:h-[130px] tablet:w-auto':
                  totalParticipantsLength > 1,
              })}
              size={getVideoSize(floatingContainerParticipant.userId)}
              displayName={floatingContainerParticipant.displayName}
              isCameraActive={floatingContainerParticipant.bVideoOn}
              isMicrophoneActive={!floatingContainerParticipant.muted}
              isSpeaking={speakingParticipants[floatingContainerParticipant.userId]}
              hasRaisedHand={raisedHands[floatingContainerParticipant.userId]}
              hasThumbsUp={getHasThumbsUp(floatingContainerParticipant.userId)}
              key={floatingContainerParticipant.userId}
              ref={(element: VideoPlayerType) =>
                onSetVideoRef(floatingContainerParticipant.userId, element)
              }
            />
          </VideoPlayerContainer>
        )}

        {!!mainContainerParticipants.length && (
          <VideoPlayerContainer
            className={mergeClassNames('mx-auto', {
              'flex flex-col grid-cols-2': mainContainerParticipants.length === 2,
              'short:flex-row laptop:grid':
                mainContainerParticipants.length === 2 && !(isSharing || isViewingScreenShare),
              'short:flex grid-cols-2 grid-rows-2': mainContainerParticipants.length > 2,
              'max-h-[calc(100vh-160px)] aspect-video h-full w-auto':
                mainContainerParticipants.length === 1 && !(isSharing || isViewingScreenShare),
              'max-w-[80vw] laptop:max-w-[calc(100vw-160px)]':
                mainContainerParticipants.length === 1 && !(isSharing || isViewingScreenShare),
              'max-w-[calc(100vw-520px)]': mainContainerParticipants.length === 1 && isPageOpened,
              'flex flex-col': isSharing || isViewingScreenShare,
            })}
            containerClassName="short:items-center"
          >
            {mainContainerParticipants.map((participant: Participant, index) => (
              <VideoPlayer
                id={`video-player-container-${participant.userId}`}
                size={getVideoSize(participant.userId)}
                containerClassName={mergeClassNames({
                  'justify-self-end laptop:justify-end':
                    index % 2 === 0 && mainContainerParticipants.length > 1,
                  'self-end': index < 2 && mainContainerParticipants.length > 2,
                  'self-start': index >= 2 && mainContainerParticipants.length > 2,
                  'max-w-[calc(80vw)] short:max-w-[calc(50vw-16px)] tablet:max-w-[calc(50vw-16px)] laptop:max-w-[calc(50vw-80px)]':
                    mainContainerParticipants.length === 2 ||
                    mainContainerParticipants.length === 4,
                  'short:max-w-[calc(31vw)]': totalParticipantsLength === 3,
                  'max-h-[calc(50vh-80px)]': mainContainerParticipants.length > 2,
                  'max-h-[calc(100vh-160px)]': mainContainerParticipants.length === 1,
                  'laptop:max-w-[231px]': isSharing || isViewingScreenShare,
                  'tablet:max-w-[80vw]': mainContainerParticipants.length === 2,
                  'short:max-w-[calc(50vw-16px)]': totalParticipantsLength === 2,
                })}
                className={mergeClassNames({
                  'max-w-[calc(80vw)] short:max-w-[calc(50vw-16px)] tablet:max-w-[calc(50vw-16px)] laptop:max-w-[calc(50vw-80px)]':
                    mainContainerParticipants.length === 2 ||
                    mainContainerParticipants.length === 4,
                  'short:w-full': totalParticipantsLength === 3,
                  'max-h-[calc(50vh-80px)]': mainContainerParticipants.length > 2,
                  'tablet:max-w-[95vw]': mainContainerParticipants.length === 2,
                  'short:max-w-[calc(50vw-16px)]': totalParticipantsLength === 2,
                })}
                showOnlyVideo={participantInPiP === participant.userId.toString() && isPiPActive}
                displayName={participant.displayName}
                isCameraActive={participant.bVideoOn}
                isMicrophoneActive={!participant.muted}
                isSpeaking={speakingParticipants[participant.userId]}
                hasRaisedHand={raisedHands[participant.userId]}
                hasThumbsUp={getHasThumbsUp(participant.userId)}
                key={participant.userId}
                ref={(element: VideoPlayerType) => onSetVideoRef(participant.userId, element)}
              />
            ))}
          </VideoPlayerContainer>
        )}
      </div>
    </div>
  );
};

export default VideoContent;
